import React from "react";
import { Container } from "@mui/material";

const CoreContainer = ({
	children,
	style,
	transparent,
	top,
	noHeight,
	width,
	borderRadius,
	height = null,
	minHeight = null,
}) => {
	const backgroundColor = transparent 
  ? "rgba(0, 0, 0, 0.01)" 
  : top 
    ? "rgba(255, 255, 255, 0.09)" 
    : "#fafafa";
	return (
		<div
			style={{
				width: "100dvw",
				height: "100dvh",
				overflowX: "hidden",
				overflowY: "auto",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: backgroundColor,
			}}>
			<Container
				style={{
					...style,
					background: "#ffffff",
					borderRadius: borderRadius ? borderRadius : window.innerWidth > window.innerHeight ? "12px" : "0",
					color: "white",
					width: width ? width : window.innerWidth > window.innerHeight ? "450px" : "100dvw",
					height: height ? height : !noHeight ? (window.innerWidth > window.innerHeight ? "630px" : "100dvh") : "",
					minHeight: minHeight ? minHeight : "",
					overflowX: "hidden",
					overflowY: "auto",
					boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
					display: "flex",
					justifyContent: "start",
					alignItems: "start",
					flexDirection: "column",
					padding: "24px",
				}}
				className="scroll-menu">
				{children}
			</Container>
		</div>
	);
};

export default CoreContainer;
