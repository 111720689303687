import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useConfig } from "context/config.context";

import "./style.scss";

import Header from "../../components/header";
import Text from "../../components/texts";
import Buttons from "../../components/buttons";
import ListBalance from "./content/listBalance/list-balance";
import EventModal from "../CalendarTimeline/eventModal";

import { moneyRender } from "../../utils/utils";

import paymentService from "../../services/paymentService";

import { Divider, Dialog } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Close } from "@material-ui/icons";

const WithdrawBalance = () => {
	const { business } = useSelector((store) => store.business);
	const [userInfo, setUserInfo] = useState([]);
	const [page, setPage] = useState(1);
	const [maxPage, setMaxPage] = useState(1);
	const [open, setOpen] = useState(false);
	const [openSecondModal, setOpenSecondModal] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(false);

	const { handleFeedback, handleLoading } = useConfig();

	const [pendingValue, setPendingValue] = useState(moneyRender(business, 0));
	const [availableValue, setAvailableValue] = useState(moneyRender(business, 0));
	const [totalValue, setTotalValue] = useState(moneyRender(business, 0));
	const [processingValue, setProcessingValue] = useState(moneyRender(business, 0));
	const [availableValueRaw, setAvailableValueRaw] = useState(0);
	const [isTransfering, setIsTransfering] = useState("False");

	const [bookingId, setBookingId] = useState(null);

	const getPaymentData = () => ({
		recipient_id: business.id,
		is_available: true,
		page_index: page,
	});

	const handleBookingId = (id) => {
		setBookingId(id);
	};

	const bookingData = {
		bookingTransactionId: bookingId,
	};

	const handleCloseModal = () => {
		setBookingId(null);
	};

	const fetchPaymentWithdrawInfo = async () => {
		handleLoading(true);
		try {
			await paymentService.fetchPaymentWithdrawInfo(getPaymentData()).then((response) => {
				if (response.data === "") {
					setUserInfo([]);
				} else {
					setUserInfo([...userInfo, ...response.data.items_list]);
					setIsTransfering(response.headers["x-has-transfer"]);
					setPendingValue(moneyRender(business, response.headers["x-total-pending"] / 100));
					setAvailableValue(moneyRender(business, response.headers["x-total-available"] / 100));
					setAvailableValueRaw(Number(response.headers["x-total-available"] / 100));
					setTotalValue(
						moneyRender(
							business,
							response.headers["x-total-processing"] / 100 +
								response.headers["x-total-pending"] / 100 +
								response.headers["x-total-available"] / 100
						)
					);
					setProcessingValue(moneyRender(business, response.headers["x-total-processing"] / 100));
				}
				const nextPage = page + 1;
				setPage(nextPage);
				setMaxPage(response.data.total_pages);
			});
			handleLoading(false);
		} catch (error) {
			handleLoading(false);
			console.error(error);
		}
	};

	const paymentWithdraw = async () => {
		handleLoading(true);
		setButtonDisabled(true);
		try {
			await paymentService.paymentWithdraw(business.id);
			setOpen(false);
			setOpenSecondModal(true);
		} catch (err) {
			handleFeedback("Ops!", "Não foi possível realizar o saque, tente novamente mais tarde!");
			console.error(err);
		} finally {
			handleLoading(false);
			setButtonDisabled(false);
		}
	};

	const updatePaymentWithdrawInfo = () => {
		if (maxPage === null || page <= maxPage) {
			fetchPaymentWithdrawInfo();
		}
		if (openSecondModal) {
			fetchPaymentWithdrawInfo();
		}
	};

	const handleWithdraw = () => {
		if (isTransfering === "True") {
			handleFeedback("Ops!", "Seu saque está sendo processado, por favor aguarde!");
			return;
		} else if (availableValueRaw < 1) {
			handleFeedback("Ops!", "Você não tem valor mínimo para saque!");
			return;
		} else {
			setOpen(true);
		}
	};

	useEffect(() => {
		fetchPaymentWithdrawInfo();
	}, []);

	return (
		<>
			<Header title="Sacar saldo" />
			<Divider />
			<main className="container-main">
				<div className="container-values">
					<div className="container">
						<Text className="balance-total">{totalValue}</Text>
						<Text>Saldo total</Text>
					</div>
					<div className="container">
						<Text className="balance-pending">{pendingValue}</Text>
						<Text>Pendente</Text>
					</div>
				</div>
				<div className="container-values">
					<div className="container">
						<Text className="balance-available">{availableValue}</Text>
						<Text>Disponível</Text>
					</div>
					<div className="container">
						<Text className="balance-processing">{processingValue}</Text>
						<Text>Processando</Text>
					</div>
				</div>
				<div className="btn-withdraw">
					<Buttons
						type="button"
						className={`${isTransfering === 'True' || availableValueRaw < 1 ? "btn-color-secondary" : "btn-color-primary"}`}
						title="Sacar Saldo"
						fullWidth
						disabled={buttonDisabled}
						handleClick={() => handleWithdraw()}
					/>
				</div>
				<ListBalance userInfo={userInfo} handleBookingId={handleBookingId} />
				{bookingId && <EventModal event={bookingData} onClose={handleCloseModal} onEdit={true} />}
			</main>
			<Dialog
				open={open}
				PaperProps={{
					style: { borderRadius: 14, maxWidth: 400 },
				}}>
				<div className="dialogContainer">
					<div className="dialogBtnClose" onClick={() => setOpen(false)}>
						<Close />
					</div>
					<div className="dialogBalanceAvailable">
						<Text className="balance-available">{availableValue}</Text>
						<Text>Disponível</Text>
					</div>
					<div className="dialogContainerText">
						<Text>O valor será enviado para sua conta cadastrada no Prit.</Text>
					</div>
					<div className="dialogContainerBtn">
						<Buttons
							variant="contained"
							type="button"
							title="Cancelar"
							className="btn-color-primary-reverse btn-default"
							fullWidth
							handleClick={() => setOpen(false)}
						/>
						<Buttons
							variant="contained"
							type="button"
							title="Sacar Saldo"
							className="btn-color-primary btn-default"
							fullWidth
							handleClick={() => paymentWithdraw()}
						/>
					</div>
				</div>
			</Dialog>
			<Dialog
				open={openSecondModal}
				PaperProps={{
					style: { borderRadius: 14, maxWidth: 400 },
				}}>
				<div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "1rem", gap: "1rem" }}>
					<div
						style={{ height: "fit-content", width: "100%", display: "flex", justifyContent: "end" }}
						onClick={() => {
							setOpenSecondModal(false);
							updatePaymentWithdrawInfo();
						}}>
						<Close />
					</div>
					<div className="dialogSecondTitle">
						<CheckCircleIcon className="dialogSecondTitle" />
						<Text className="dialogSecondTitle">Saque realizado!</Text>
					</div>
					<div>
						<Text className="dialogSecondText">
							Pagamento por PIX Saques solicitados antes das 15h, são efetivados no mesmo dia. Solicitações após esse
							horário são realizadas no próximo dia útil.
						</Text>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default WithdrawBalance;
