import React from "react";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";

import useKeyboardVisibility from "utils/useKeyboardVisibility";

const CoreButton = (props) => {
	const {
		toLogin,
		text,
		toCode,
		toData,
		toWelcome,
		toSchedule,
		onClick,
		id,
		params,
		disable,
		invertColors,
		style,
		color,
	} = props;

	const { isKeyboardVisible, keyboardOffset } = useKeyboardVisibility();

	const navigate = useNavigate();

	const handleClick = () => {
		if (!disable) {
			if (onClick) {
				onClick();
			}

			if (toLogin) {
				navigate("/login", { state: params });
			} else if (toCode) {
				navigate("/login/code", { state: params });
			} else if (toData) {
				navigate("/login/data", { state: params });
			} else if (toWelcome) {
				navigate("/welcome", { state: params });
			} else if (toSchedule) {
				navigate("/subscription/onboarding", { state: params });
			}
		}
	};

	const buttonStyle = isKeyboardVisible
		? {
				position: "static",
				left: "50%",
				bottom: `${keyboardOffset}px`,
				minBottom: "5px",
				width: "90%",
				maxWidth: "400px",
				zIndex: 1000,
		}
		: null;

	return (
		<Button
			id={id}
			onClick={handleClick}
			style={{
				...style,
				...buttonStyle,
				fontSize: "16px",
				fontWeight: "400",
				padding: "1rem 0.5rem",
				borderRadius: "10px",
				backgroundColor: disable ? "gray" : color ? color : invertColors ? "transparent" : "#39ADC4",
				color: invertColors ? "#39ADC4" : "#fff",
				cursor: disable ? "unset" : "pointer",
				overflow: "hidden",
				width: "100%",
				textTransform: "none",
				boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
				fontFamily: "Poppins",
			}}
			variant="contained"
			disabled={disable}>
			{text ? text : "Avançar"}
		</Button>
	);
};

export default CoreButton;
