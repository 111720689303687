import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { v4 } from 'uuid';
import { Text } from 'components';
import CoreContainer from '../../components/container';
import ServiceChip from '../../components/serviceChip';
import { Inputs } from "components";
import { Button as GlobalButton} from "components";
import { frequencyTypesAndRules } from '../../utils/utils';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBackIos, Close } from '@material-ui/icons';
import { repeatSchedule, initialRepeatScheduleForm } from '../../helpers/schemas/repeatSchedule-schema.js';
import { Dialog } from "@material-ui/core";
import './index.scss';
import NewReservationDialog from '../newReservationPost/index.jsx';

const RecurrentScheduleDialog = ({ onClose, serviceData }) => {
  const [repeatedData, setRepeatedData] = useState({
    listDataItems: [],
    selectedDate: null,
  });
  const [saveReservation, setSaveReservation] = useState(false);

  const repeatScheduleVariables = frequencyTypesAndRules();
  const booking = serviceData.bookings[0];
  const startTime = booking.start_time;
  const endTime = booking.end_time;


  const formatTime = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleTimeString("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const {
    control,
    formState: { errors, isValid },
    watch,
  } = useForm({
    resolver: yupResolver(repeatSchedule),
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: initialRepeatScheduleForm,
  });

  const ruleOne = watch('rule');

  useEffect(()=>{
    setRepeatedData({listDataItems: [], selectedDate: null});
  },[ watch('frequency'), watch('rule'), watch('ruleTwo'), watch('selectedDate') ]);

  const onPressIcon = (tempId) => {
    setRepeatedData((prevState) => ({
      ...prevState,
      listDataItems: prevState.listDataItems.filter(
        (item) => item.temp_id !== tempId
      ),
    }));
  };

  const calcDays = () => {
    const currentFrequency = watch("frequency");
    const currentRuleOne = watch("rule");
    const currentRuleTwo = watch("ruleTwo");

    if (!booking) return;

    const listBookingsNew = [];

    const refAdd =
      currentFrequency === "Mensalmente" ? "month" : "days";

    const adjustedFrequency =
      currentFrequency === "Mensalmente"
        ? 1
        : parseInt(currentFrequency, 10);

    const cloneItem = _.cloneDeep(booking);

    cloneItem.start_time = moment(booking.start_time)
      .add(adjustedFrequency, refAdd)
      .format("YYYY-MM-DDTHH:mm:ssZ");

    cloneItem.end_time = moment(booking.end_time)
      .add(adjustedFrequency, refAdd)
      .format("YYYY-MM-DDTHH:mm:ssZ");

    // Definimos um temp_id, caso seja preciso identificar cada clone
    cloneItem.temp_id = v4();

    listBookingsNew.push(cloneItem);

    const reservas = currentRuleTwo - 1;

    const diffDate = repeatedData.selectedDate
      ? moment(repeatedData.selectedDate).diff(
          moment(booking.start_time),
          "days"
        )
      : 0;

    const reservasDataSelecionada = diffDate / adjustedFrequency;

    switch (currentRuleOne) {
      case "Depois de":
        for (let i = reservas; i >= 1; i--) {
          const lastClone = listBookingsNew[listBookingsNew.length - 1];
          const cloneItemB = _.cloneDeep(lastClone);

          cloneItemB.start_time = moment(lastClone.start_time)
            .add(adjustedFrequency, refAdd)
            .format("YYYY-MM-DDTHH:mm:ssZ");

          cloneItemB.end_time = moment(lastClone.end_time)
            .add(adjustedFrequency, refAdd)
            .format("YYYY-MM-DDTHH:mm:ssZ");

          cloneItemB.temp_id = v4();
          listBookingsNew.push(cloneItemB);
        }
        break;

      case "Na data selecionada":
        for (let i = reservasDataSelecionada; i > 1; i--) {
          const lastClone = listBookingsNew[listBookingsNew.length - 1];
          const cloneItemB = _.cloneDeep(lastClone);

          cloneItemB.start_time = moment(lastClone.start_time)
            .add(adjustedFrequency, refAdd)
            .format("YYYY-MM-DDTHH:mm:ssZ");

          cloneItemB.end_time = moment(lastClone.end_time)
            .add(adjustedFrequency, refAdd)
            .format("YYYY-MM-DDTHH:mm:ssZ");

          cloneItemB.temp_id = v4();
          listBookingsNew.push(cloneItemB);
        }
        break;

      default:
        break;
    }

    setRepeatedData((prevState) => ({
      ...prevState,
      listDataItems: listBookingsNew,
    }));
  };

  const handleSaveBooking = () => {
    setSaveReservation(true);
    console.log(saveReservation);
    
  };

  return (
    <CoreContainer top={true} style={{ padding: "1rem" }}>
      <header className="headerContainer">
        <ArrowBackIos
          style={{ width: "30px", color: "#616161", cursor: "pointer" }}
          onClick={onClose}
        />
        <Text>Recorrência</Text>
      </header>

      <section className="serviceContainer">
        <Text style={{ color: "#09122C", marginBottom: "0.5rem" }}>
          Serviço
        </Text>
        <div>
          <ServiceChip
            serviceDuration={booking.service.service.duration}
            serviceStartTime={formatTime(startTime)}
            serviceEndTime={formatTime(endTime)}
            serviceName={booking.service.service.name}
            servicePrice={booking.service.price}
            serviceProfessional={booking.professional.name}
          />
        </div>
      </section>

      <div className="frequencyForm">
        <Text style={{ marginBottom: "0.5rem" }}>Frequência</Text>
        <Inputs
          type="select"
          name="frequency"
          control={control}
          options={repeatScheduleVariables.frequencyTypes}
        />
      </div>

      <div className="repetitionBlock">
        <Text style={{ marginTop: "1rem" }}>Finalizar Repetição</Text>
        <div>
          <Inputs
            type="select"
            name="rule"
            control={control}
            options={repeatScheduleVariables.untilRuleOne}
            style={{ width: "100%" }}
          />
          {ruleOne === 'Na data selecionada'  ? (
            <Inputs
            type='date'
            name='selectedDate'
            control={control}
            style={{ width: "100%" }}
          />
          ): 
            <Inputs
              type="select"
              name="ruleTwo"
              control={control}
              options={repeatScheduleVariables.untilRuleTwo}
              style={{ width: "100%" }}
            />
          }
          
        </div>
      </div>

      <Text variant={"body1"} style={{marginTop: '0.5rem'}}>
        Os seguintes agendamentos serão adicionados ao calendário
      </Text>

      {repeatedData.listDataItems.length > 0 && 
        <div className='calculatedList'>
          {repeatedData.listDataItems.map((item) => {
            const formatDate = moment(item.start_time)
            .locale('pt-br')
            .format('ddd, D [de] MMMM [de] YYYY [às] HH:mm');
            const firstLetterUppercase = formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
            return (
            <div key={item.temp_id} className='listItem'>
              <div>
                <Text variant={'body1'} style={{letterSpacing: '1.5px'}}>{firstLetterUppercase}</Text>
              </div>
              <div>
                <Close style={{ width: "20px", color: "#616161", cursor: "pointer" }} onClick={()=>{onPressIcon(item.temp_id);}}/>
              </div>
            </div>
            );
          })}
        </div>
      }

      <div className="buttonContainer">
        {repeatedData.listDataItems.length > 0 ?
          <GlobalButton
            type="button"
            disabled={!isValid}
            title="Salvar Repetição"
            variant={"outlined"}
            className="buttonCalc"
            handleClick={handleSaveBooking}
          /> :
          <GlobalButton
            type="button"
            disabled={!isValid}
            title="Calcular"
            variant={"outlined"}
            className="buttonCalc"
            handleClick={calcDays}
          />
        } 
      </div>

      <Dialog
        open={saveReservation}
        fullScreen
				PaperProps={{
					style: {backgroundColor: "transparent"}
				}}>
        <NewReservationDialog
          onClose={()=> setSaveReservation(false) }
          reservationList={repeatedData?.listDataItems}
        />
      </Dialog>
    </CoreContainer>
  );
};

export default RecurrentScheduleDialog;
