import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Divider } from "@mui/material";
import { Grid } from "@material-ui/core";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { BussinesSchema } from "helpers/schemas";
import Constants from "helpers/Constants";

import { findDdiNumber, randomInt, telephoneNumberMask, trimTelephoneNumber } from "utils";

import businessService from "services/businessService";
import { useConfig } from "context/config.context";

import { Button, Dialog, Header, Icons, Inputs, Text } from "components";

import imageProfileDefault from "assets/icons/profile.png";
import clsx from "clsx";
import styled from "styled-components";
import "./style.scss";

export default function BusinessProfile() {
	const [photos, setPhotos] = useState([]);
	const [deletePhoto, setDeletePhoto] = useState(null);
	const [phone, setPhone] = useState({ id: 1, phone: "", ddi: 1 });

	const refUploadCoverProfile = useRef(null);
	const refUploadLogoProfile = useRef(null);
	const refUploadPhotosProfile = useRef(null);
	const { business } = useSelector((store) => store.business);
	const dispatch = useDispatch();

	const { handleFeedback, loading, handleLoading } = useConfig();

	const { handleSubmit, control, setValue, watch } = useForm({
		resolver: yupResolver(BussinesSchema.businessProfileSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: BussinesSchema.initialBusinessForm,
	});

	const fullNameRef = useRef(null);
	const descriptionRef = useRef(null);
	const cellphoneRef = useRef(null);
	const socialInstagramRef = useRef(null);
	const socialDesktopRef = useRef(null);
	const zipcodeRef = useRef(null);
	const addressStreetRef = useRef(null);
	const addressNumberRef = useRef(null);
	const addressComplementRef = useRef(null);
	const addressReferencePointRef = useRef(null);
	const addressNeighborhoodRef = useRef(null);
	const addressCityRef = useRef(null);
	const addressStateRef = useRef(null);

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				handleSubmit(onSubmit)();
			}
		}
	};

	useEffect(() => {
		if (watch("zipcode")?.length === 9) {
			handleZipCode();
		}
	}, [watch("zipcode")]);

	const handleZipCode = () => {
		handleLoading(true);
		try {
			const zipcode = watch("zipcode").replace("-", "");
			axios
				.get(`https://viacep.com.br/ws/${zipcode}/json/`)
				.then((response) => {
					const data = response.data;
					setValue("addressStreet", data.logradouro);
					setValue("addressState", data.uf);
					setValue("addressCity", data.localidade);
					setValue("addressNeighborhood", data.bairro);
				})
				.catch((error) => {
					console.error("Erro ao buscar o CEP:", error);
				});
		} catch (error) {
			console.error("Erro ao buscar o CEP:", error);
		} finally {
			handleLoading(false);
		}
	};

	const loadData = async () => {
		handleLoading(true);
		const { name, description, phone, social, address, photos } = business;
		setPhone(phone);
		setPhotos(photos);

		const treatPhone = findDdiNumber(phone);
		setPhone(treatPhone);

		setValue("name", name);
		setValue("description", description);
		setValue("cellphone", telephoneNumberMask(treatPhone?.phone));
		setValue("socialInstagram", social.instagram);
		setValue("socialDesktop", social.desktop);
		setValue("zipcode", address.postalCode);
		setValue("addressStreet", address.street);
		setValue("addressNumber", address.number);
		setValue("addressComplement", address.unit);
		setValue("addressReferencePoint", address.referencePoint);
		setValue("addressNeighborhood", address.neighborhood);
		setValue("addressCity", address.city);
		setValue("addressState", address.state);
		handleLoading(false);
	};

	const onSubmit = async (data) => {
		handleLoading(true);
		const businessData = {
			name: data.name,
			description: data.description,
			phone: `${phone.ddi}${trimTelephoneNumber(data.cellphone)}`,
			social_instagram: data.socialInstagram,
			website: data.socialDesktop,
			address_postal_code: data.zipcode,
			address_street: data.addressStreet,
			address_number: data.addressNumber,
			address_unit: data.addressComplement,
			address_reference_point: data.addressReferencePoint,
			address_neighborhood: data.addressNeighborhood,
			address_city: data.addressCity,
			address_state: data.addressState,
		};

		const response = await businessService.putBusiness(business?.id, businessData);
		if (response.status === 200) {
			handleFeedback("Sucesso", "Dados salvos som sucesso!");
			dispatch({
				type: Constants.GET_BUSINESS,
				payload: {
					business: {
						...business,
						...businessData,
					},
				},
			});
		} else {
			handleFeedback("Ops!", "Não foi possível salvar as informações!", "error");
		}
		handleLoading(false);
	};

	const handleClickUploadCover = () => {
		refUploadCoverProfile.current.click();
	};

	const handleClickPhotosProfile = () => {
		refUploadPhotosProfile.current.click();
	};

	const handleClickLogoProfile = () => {
		refUploadLogoProfile.current.click();
	};

	const handleSubmitImage = async (event) => {
		if (event.target.files[0]) {
			handleLoading(true);
			const field = event.target.name;
			const file = event.target.files[0];
			const formData = new FormData();
			const fileName = randomInt(1000000000).toString() + "_" + file.name;
			formData.append("image", file, fileName);
			try {
				const response = await businessService.uploadImage(formData);
				if (response.status === 200) {
					if (field === "logo") {
						handleUpdateLogo({
							thumbnailUrl: "https://storage.googleapis.com/prit-images/" + fileName,
						});
					} else {
						handleSendPhotos(
							{
								business_id: business.id,
								url: "https://storage.googleapis.com/prit-images/" + fileName,
							},
							field
						);
					}
				}
			} catch (error) {
				console.error(error);
				handleFeedback("Ops!", "Não foi possível enviar a imagem!", "error");
				handleLoading(false);
			}
		}
	};

	const handleUpdateLogo = async (data) => {
		const response = await businessService.putBusiness(business.id, data);
		if (response.status === 200) {
			handleFeedback("Sucesso", "Logo atualizada com sucesso!");
			dispatch({
				type: Constants.GET_BUSINESS,
				payload: {
					business: {
						...business,
						thumbnailUrl: data.thumbnailUrl,
					},
				},
			});
		}
		handleLoading(false);
	};

	const handleSendPhotos = async (data, updateCover) => {
		const response = await businessService.sendPhotos(data);
		if (response.status === 200) {
			if (updateCover === "cover" && photos.length) {
				let order = 1;
				const new_photos = [
					{
						id: response.data.id,
						order,
					},
				];
				photos.forEach((element) => {
					order++;
					new_photos.push({ id: element.id, order });
				});
				await businessService.updatePhotos({ photos: new_photos, business_id: business.id }, business.id);
				handleFeedback("Sucesso", "Foto salva com sucesso!");
			}
			const watchAllFields = watch();
			await onSubmit(watchAllFields);
			await loadData();
		}
		handleLoading(false);
	};

	const handleSelectPhotoToDelete = (photoId) => {
		setDeletePhoto(photoId);
	};

	const handleDeletePhoto = async (photoId) => {
		const response = await businessService.deletePhoto(photoId);
		if (response.status === 204) {
			handleFeedback("Sucesso", "Foto removida com sucesso!");
			const newPhotos = photos.filter((photo) => photo.id !== photoId);
			setPhotos(newPhotos);
			setDeletePhoto(null);
		}
	};

	useEffect(() => {
		if (business.id) {
			loadData();
		}
	}, [business]);

	return (
		<>
			<Header title="Perfil da Empresa" />
			<Divider />
			<div className="form business-profile">
				<StyledCover className="form-cover" background={photos?.length ? photos[0].url : ""}>
					<div>
						<input type="file" name="cover" ref={refUploadCoverProfile} hidden={true} onChange={handleSubmitImage} />
						<button
							onClick={handleClickUploadCover}
							className={clsx("form-cover-button-upload", loading && "disabled")}
							disabled={loading}>
							<Icons.Edit />
						</button>
					</div>
				</StyledCover>
				<div className="form-logo">
					<StyledLogo className="profile-logo" background={business.thumbnailUrl}>
						<>
							<input type="file" name="logo" ref={refUploadLogoProfile} hidden={true} onChange={handleSubmitImage} />
							<button
								onClick={handleClickLogoProfile}
								className={clsx("form-logo-button-upload", loading && "disabled")}
								disabled={loading}>
								<Icons.Photo />
							</button>
						</>
					</StyledLogo>
				</div>
				<div className="form-content">
					<Inputs
						control={control}
						name="name"
						type="text"
						label="Nome completo"
						inputRef={fullNameRef}
						handleKeyDown={(e) => handleKeyDown(e, descriptionRef)}
					/>
					<Inputs
						control={control}
						name="description"
						type="textarea"
						label="Descrição"
						inputRef={descriptionRef}
						handleKeyDown={(e) => handleKeyDown(e, cellphoneRef)}
					/>
					<Inputs
						control={control}
						name="cellphone"
						type="tel"
						label="Telefone"
						inputRef={cellphoneRef}
						handleKeyDown={(e) => handleKeyDown(e, socialInstagramRef)}
						placeholder="Ex: (11) 9 9300-0000"
						ddi={phone.id || 1}
						inputProps={{
							pattern: "[0-9]*",
							inputMode: "numeric",
						}}
					/>
					<Inputs
						control={control}
						name="socialInstagram"
						type="text"
						label="Instagram"
						inputRef={socialInstagramRef}
						handleKeyDown={(e) => handleKeyDown(e, socialDesktopRef)}
					/>
					<Inputs
						control={control}
						name="socialDesktop"
						type="text"
						label="Site"
						inputRef={socialDesktopRef}
						handleKeyDown={(e) => handleKeyDown(e, zipcodeRef)}
					/>

					<div className="input-form-upload photos-profile">
						<div className="photos-input-profile">
							<input
								type="file"
								name="photos"
								ref={refUploadPhotosProfile}
								hidden={true}
								onChange={handleSubmitImage}
							/>
							<Text style={{ marginLeft: "0.7rem" }}>Portfolio</Text>
							<button
								onClick={handleClickPhotosProfile}
								className={clsx("button-upload", loading && "disabled")}
								disabled={loading}>
								Fazer upload das fotos
							</button>
						</div>
						<div className="list-photos">
							{photos?.length ? (
								photos.map((photo) => {
									return (
										<StyledPhotos key={photo.id} background={photo.url}>
											<button onClick={() => handleSelectPhotoToDelete(photo.id)} className="button-delete-photo">
												<Icons.Trash style={{ width: "16px", height: "16px", fill: "white" }} />
											</button>
										</StyledPhotos>
									);
								})
							) : (
								<>Não há fotos em seu Portfolio!</>
							)}
						</div>
					</div>
					<div>
						<Text style={{ marginLeft: "0.7rem", marginBottom: "1rem", marginTop: "1rem" }}>Endereço</Text>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Inputs
									control={control}
									name="zipcode"
									type="text"
									label="CEP"
									placeholder="Ex: 00000-000"
									inputRef={zipcodeRef}
									handleKeyDown={(e) => handleKeyDown(e, addressStreetRef)}
									inputProps={{
										pattern: "[0-9]*",
										inputMode: "numeric",
										maxLength: 9,
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<Inputs
									control={control}
									name="addressStreet"
									type="text"
									label="Logradouro"
									inputRef={addressStreetRef}
									handleKeyDown={(e) => handleKeyDown(e, addressNumberRef)}
								/>
							</Grid>
							<Grid item xs={6}>
								<Inputs
									control={control}
									name="addressNumber"
									type="text"
									label="Número"
									inputRef={addressNumberRef}
									handleKeyDown={(e) => handleKeyDown(e, addressComplementRef)}
								/>
							</Grid>
							<Grid item xs={6}>
								<Inputs
									control={control}
									name="addressComplement"
									type="text"
									label="Complemento"
									helper="Caso não possua coloque 'sem complemento'"
									inputRef={addressComplementRef}
									handleKeyDown={(e) => handleKeyDown(e, addressReferencePointRef)}
								/>
							</Grid>
							<Grid item xs={6}>
								<Inputs
									control={control}
									name="addressReferencePoint"
									type="text"
									label="Ponto de Referência"
									inputRef={addressReferencePointRef}
									handleKeyDown={(e) => handleKeyDown(e, addressNeighborhoodRef)}
								/>
							</Grid>
							<Grid item xs={6}>
								<Inputs
									control={control}
									name="addressNeighborhood"
									type="text"
									label="Bairro"
									inputRef={addressNeighborhoodRef}
									handleKeyDown={(e) => handleKeyDown(e, addressCityRef)}
								/>
							</Grid>
							<Grid item xs={6}>
								<Inputs
									control={control}
									name="addressCity"
									type="text"
									label="Cidade"
									inputRef={addressCityRef}
									handleKeyDown={(e) => handleKeyDown(e, addressStateRef)}
								/>
							</Grid>
							<Grid item xs={6}>
								<Inputs
									control={control}
									name="addressState"
									type="text"
									label="Estado"
									inputRef={addressStateRef}
									handleKeyDown={(e) => handleKeyDown(e, null)}
								/>
							</Grid>
						</Grid>
					</div>
				</div>
			</div>
			<div className="button-container">
				<Button
					type="button"
					title="Salvar"
					variant="contained"
					handleClick={handleSubmit(onSubmit)}
					className="btn-color-primary btn-default"
					fullWidth={true}
				/>
			</div>
			<Dialog
				title="Realmente deseja remover esta foto?"
				isOpen={!!deletePhoto}
				handleFirstBtn={() => handleDeletePhoto(deletePhoto)}
				handleSecondBtn={() => setDeletePhoto(null)}
				lblBtnFirst="Sim"
				colorBtnSecond={"#FF0000"}
			/>
		</>
	);
}

const StyledLogo = styled.div`
	background-image: url("${(props) => props.background || imageProfileDefault}");
	background-repeat: no-repeat;
	background-size: ${(props) => (props.background ? "cover" : "initial")};
	background-position: center;
`;

const StyledCover = styled.div`
	${(props) => (props.background ? `background-image: url("${props.background}");` : "")}
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
`;

const StyledPhotos = styled.div`
	${(props) => (props.background ? `background-image: url("${props.background}");` : "")}
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
`;
