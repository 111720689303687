import React from "react";
import { Divider, Typography } from "@mui/material";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	flagIcon: {
		width: "30px",
		height: "30px",
		margin: "1vw",
		color: "gray",
	},
	elapsedTime: {
		fontSize: "calc(0.5vw + 5px)",
		fontWeight: 100,
		color: "lightgray",
	},
	notify: {
		marginRight: 0,
		marginLeft: "auto",
		color: "#3689ea",
		textTransform: "none",
	},
}));

const NotificationCard = (props) => {
	const classes = useStyles();

	const { notifcation, onClick, lastReminderCheckDate } = props;

	const elapsedTime = (date) => {
		let time = Date.now();

		time -= new Date(date);

		if (time < 3600000) {
			return Math.floor(time / 60000) + " minuto(s) atrás";
		}

		if (time >= 3600000 && time < 3600000 * 24) {
			return Math.floor(time / 3600000) + " hora(s) atrás";
		}

		return Math.floor(time / (3600000 * 24)) + " dia(s) atrás";
	};

	return (
		<>
			<div style={{ display: "flex", flexDirection: "row", padding: "5px", cursor: "pointer" }} onClick={onClick}>
				<EmojiFlagsIcon className={classes.flagIcon}></EmojiFlagsIcon>
				<div>
					<Typography style={{ fontWeight: "bold", fontSize: "calc(0.5vw + 5px)", color: "gray" }}>
						{notifcation.title}
					</Typography>
					<Typography style={{ fontSize: "calc(0.5vw + 5px)", fontWeight: 400, color: "gray" }}>
						{notifcation.message}
					</Typography>
					<Typography
						style={{
							fontSize: "calc(0.5vw + 5px)",
							fontWeight: 100,
							color: "lightgray",
						}}>
						{elapsedTime(notifcation.createdAt)}
					</Typography>
				</div>
				{lastReminderCheckDate < new Date(notifcation.createdAt) && (
					<Typography style={{ marginRight: 0, marginLeft: "auto", color: "#3689ea", textTransform: "none" }}>
						o
					</Typography>
				)}
			</div>
			<Divider></Divider>
		</>
	);
};
export default NotificationCard;
