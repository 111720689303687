import React, { useEffect, useState } from 'react';
import './index.scss';
import CoreContainer from '../../components/container';
import { Dialog, MenuItem, TextField } from '@material-ui/core';
import { addMinutes, format, parseISO } from "date-fns";
import SelectServiceDialog from "../schedule/content/dialog-select-service";
import { ArrowBackIos } from "@material-ui/icons";
import { useSelector } from 'react-redux';
import ServiceChip from '../../components/serviceChip';
import { Text } from '../../components';
import { Button as GlobalButton } from '../../components';
import { useConfig } from '../../context/config.context';
import { formatDate } from '../../utils/utils';

const EditBooking = ({ booking, onClose, onSave }) => {
  const { business } = useSelector((store) => store.business);
  const [professional, setProfessional] = useState(null);
  const [service, setService] = useState(null);

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [openServiceDialog, setOpenServiceDialog] = useState(false);
  const [otherServices, setOtherServices] = useState([]);
  
  const { handleFeedback } = useConfig();

  useEffect(() => {
    if (!booking || !booking.booking) return;

    setDate(formatDate(booking.booking.start_time));
    setTime(booking.booking.start_time.toLocaleTimeString().slice(0, 5));

    if (booking.professional && business?.professionals?.length) {
      const foundProfessional = business.professionals.find(
        (p) => p.id === booking.professional.id
      );
      setProfessional(foundProfessional ?? null);
    }
  }, [booking, business]);

  useEffect(()=>{
    console.log(booking);
  },[]);

  const handleChangeProfessional = (event) => {
    setProfessional(event.target.value);
  };

  const generateUpdatedBooking = () => {
    const dateTimeString = `${date}T${time}:00`;
    const startTimeDate = parseISO(dateTimeString);

    const duration = service?.duration ?? booking?.service?.duration ?? 0;
    const endTimeDate = addMinutes(startTimeDate, duration);

    const startTimeISO = format(startTimeDate, "yyyy-MM-dd'T'HH:mm:ssxxx");
    const endTimeISO   = format(endTimeDate,   "yyyy-MM-dd'T'HH:mm:ssxxx");
    const formattedTime = format(startTimeDate, "HH:mm");

    return {
      ...booking,
      professional: { ...professional },
      start_time: startTimeISO,
			end_time: endTimeISO,
      date: startTimeISO,
      booking: {
        id: null,
        professional_id: professional.id,
        service_id: service?.id,
        start_time: startTimeDate,
        end_time: endTimeDate,
      },
      price: service?.price ?? booking?.service?.price,
      service: {
        categoryId : service ? service?.categoryId : booking?.service?.categoryId ,
        duration: service ? service?.duration : booking?.service?.duration,
        id: service ? service?.id : booking?.service?.id,
        price: service ? service?.price : booking?.service?.price,
        pricingTypeId: service ? service?.professionalId : booking?.service?.pricingTypeId,
        service: {
          ...service,
          categoryId : service ? service?.service?.categoryId : booking?.service?.service?.categoryId ,
          duration: service ? service?.service?.duration : booking?.service?.service?.duration ,
          id:  service ? service?.service?.id : booking?.service?.service?.id ,
          name:  service ? service?.service?.name : booking?.service?.service?.name,
          price: service ? service?.service?.price : booking?.service?.service?.price,
          pricingTypeId: service ? service?.service?.pricingTypeId : booking?.service?.service?.pricingTypeId,
        }
      },
      time: formattedTime,
    };
  };

  const handleSave = () => {
    const updatedBooking = generateUpdatedBooking();
    onSave(updatedBooking);
  };

  return (
    <CoreContainer top={true} style={{ padding: "1rem" }}>
      <div className='headerContainer'>
        <ArrowBackIos
          style={{ width: "30px", color: "#616161", cursor: 'pointer' }}
          onClick={onClose}
        />
        <Text>Editar Serviço</Text>
      </div>
      <div className='formContainer'>
        <Text>Data e Hora</Text>
        <div className="inputTimeContainer">
          <TextField
            className="inputTime"
            value={date}
            variant="outlined"
            onChange={(e) => setDate(e.target.value)}
            type="date"
          />
          <TextField
            className="inputTime"
            value={time}
            variant="outlined"
            onChange={(e) => setTime(e.target.value)}
            type="time"
          />
        </div>
        <Text>Profissional</Text>
        <div>
          <TextField
            select
            variant="outlined"
            value={professional ?? ""}
            onChange={handleChangeProfessional}
            fullWidth
          >
            {business?.professionals?.map((prof) => (
              <MenuItem key={prof.id} value={prof}>
                {prof.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <Text>Serviço</Text>
          <ServiceChip
            serviceDuration={
              service 
              ? service?.duration 
              : booking?.service?.duration + "m"
            }
            serviceName={
              service 
              ? service?.service?.name 
              : booking?.service?.service?.name
            }
            servicePrice={
              service 
              ? service?.price 
              : booking?.service?.price
            }
            serviceProfessional={
              otherServices 
              && otherServices.length > 0 
              ? professional?.name 
              : ''
            }
            edit={true}
            onClick={() => {
              if (!professional) {
                handleFeedback(
                  "Atenção",
                  "Você deve escolher o profissional primeiro!",
                  "warning"
                );
              } else {
                setOpenServiceDialog(true);
              }
            }}
          />
        </div>
      </div>

      <footer className='footerContainer'>
        <GlobalButton
          type='button'
          title='Salvar'
          variant={'outlined'}
          className={'btn-color-primary'}
          handleClick={handleSave}
        />
      </footer>

      <Dialog
        open={openServiceDialog}
        fullScreen
        PaperProps={{ style: { backgroundColor: "transparent" } }}
      >
        <SelectServiceDialog
          onClose={() => setOpenServiceDialog(false)}
          select={setService}
          professionalCategoriesID={professional?.categories}
          professionalID={[professional?.id]}
        />
      </Dialog>
    </CoreContainer>
  );  
};

export default EditBooking;
