import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Alert, Box, Container, Stack, Typography } from "@mui/material";

import { useConfig } from "context/config.context";
import { businessPlan } from "services/businessService";

import { ButtonCommom, HeaderWelcome } from "components";

const PaymentPixQrCode = () => {
	const location = useLocation();
	const navigation = useNavigate();
	const businessInfor = useSelector((state) => state.business);
	const { handleFeedback } = useConfig();

	const [copyPix, setCopyPix] = useState(false);
	const [error, setError] = useState("");
	const [startTimer, setStartTimer] = useState(false);

	const pixCode = location?.state?.qrcode;

	const copyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(pixCode);
			setCopyPix(true);
			if (!startTimer) setStartTimer(true);
		} catch (err) {
			setCopyPix(false);
			setError("Falha ao copiar o código PIX. Tente novamente.");
		}
	};

	useEffect(() => {
		if (startTimer) {
			const timer = setInterval(async () => {
				const { data } = await businessPlan(businessInfor.businessInfo.id);
				if (data && data[0].status === "active") {
					clearInterval(timer);
					handleFeedback("Pagamento Confirmado", "Seu pagamento foi confirmado com sucesso.", "success");
					navigation("/schedule");
				}
			}, 5000);
			return () => {
				clearTimeout(timer);
			};
		}
	}, [startTimer]);

	return (
		<Container
			maxWidth="sm"
			sx={{
				backgroundColor: "#fff",
				borderRadius: 3,
				color: "#393762",
				minHeight: "100vh",
				padding: { xs: "16px", sm: "32px" },
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}>
			<HeaderWelcome />

			<Typography
				variant="h5"
				align="center"
				color="textSecondary"
				gutterBottom
				sx={{ padding: "5% 0 2% 0", color: "#393762" }}>
				Pagamento via PIX
			</Typography>

			<Typography
				variant="subtitle2"
				align="center"
				color="textSecondary"
				component="p"
				sx={{ marginBottom: "2%", fontSize: "16px" }}>
				1. Copie o código abaixo
			</Typography>
			<Typography
				variant="subtitle2"
				align="center"
				color="textSecondary"
				component="p"
				sx={{ marginBottom: "10%", fontSize: "16px" }}>
				2. Pague via PIX Copia e Cola em qualquer aplicativo habilitado
			</Typography>

			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					padding: "16px",
					backgroundColor: "#f5f5f5",
					borderRadius: "8px",
					boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
					mb: 2,
					marginTop: "5%",
					cursor: "pointer",
					":hover": {
						backgroundColor: "#f0f0f0",
					},
				}}
				onClick={copyToClipboard}>
				<Typography
					variant="body1"
					sx={{
						wordBreak: "break-all",
						fontSize: "18px",
						color: "#393762",
						marginRight: "8px",
					}}>
					{pixCode}
				</Typography>
			</Box>

			{error && (
				<Stack sx={{ width: "100%", mt: 2 }} spacing={2}>
					<Alert variant="outlined" severity="error">
						{error}
					</Alert>
				</Stack>
			)}

			<Typography
				variant="subtitle2"
				sx={{
					fontSize: "15px",
					fontWeight: "bold",
					textAlign: "center",
					color: "grey",
					marginTop: "3%",
				}}>
				Você tem até 10 minutos para fazer o pagamento.
			</Typography>

			<ButtonCommom
				text={copyPix ? "PIX copiado" : "Copiar código PIX"}
				onClick={copyToClipboard}
				disabled={copyPix}
				style={{
					marginTop: "10%",
					width: "100%",
					fontSize: "1em",
					height: "60px",
					backgroundColor: copyPix ? "#4CAF50" : "#4A90E2",
					color: "#fff",
					borderRadius: "8px",
					cursor: "pointer",
				}}
			/>
		</Container>
	);
};

export default PaymentPixQrCode;
