import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button, Dialog, Divider } from "@material-ui/core";
import { useMixpanel } from "react-mixpanel-browser";

import { sendMsgWavy, fetchStoreVersion, userAuthAi } from "services/loginService";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useConfig } from "context/config.context";
import { useLogin } from "context/login.context";
import { LoginSchema } from "helpers/schemas";

import { AppBar, Chip, Container, Button as GlobalButton, Inputs, Text } from "components";
import { getDdiNumber, trimTelephoneNumber } from "utils";

import useStyles from "./styles-login";
import "styles/login/index.scss";

const Login = () => {
	const classes = useStyles();
	const location = useLocation();
	const navigate = useNavigate();
	const { handleLoading } = useConfig();
	const { startBusinessState } = useLogin();
	const mixpanel = useMixpanel();

	const [modalMessage, setModalMessage] = useState("");
	const [openModal, setOpenModal] = useState(false);

	const [typeAuthVerify, setTypeAuthVerify] = useState(false);

	const isNewBusiness = () => !!location?.state?.newBusiness;

	const {
		control,
		watch,
		setValue,
		formState: { isValid },
	} = useForm({
		resolver: yupResolver(isNewBusiness() ? LoginSchema.newBusinessSchema : LoginSchema.loginSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: LoginSchema.initialLoginForm,
	});

	const fullNameRef = useRef(null);
	const emailRef = useRef(null);
	const businessNameRef = useRef(null);
	const telephoneRef = useRef(null);

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				sendCode();
			}
		}
	};

	const sendCode = () => {
		handleLoading(true);
		if (watch("phone")) {
			const state = {
				telephoneNumber: watch("phone"),
				ddi: getDdiNumber(watch("ddi")),
				fullName: watch("name"),
				email: watch("email"),
				businessName: watch("businessName"),
				isNewBusiness: isNewBusiness(),
			};
			startBusinessState(state);
			const userPhoneNumber = `+${getDdiNumber(watch("ddi"))}${trimTelephoneNumber(watch("phone"))}`;
			const authType = state.ddi === "55" ? typeAuthVerify : "sms";
			switch (authType) {
				case "sms":
					sendMsgWavy(userPhoneNumber)
						.then(() => {
							mixpanel.identify(userPhoneNumber);
							mixpanel.people.set({
								$name: watch("name") || "Nome padrão",
								$email: watch("email") || "email padrão",
							});
							isNewBusiness
								? mixpanel.track("onb. Avançar Dados pessoais - Web Business")
								: mixpanel.track("onb. Inserir Telefone - Web Business");
							navigate("/login/code", state);
						})
						.catch((error) => {
							setModalMessage(error.message);
							setOpenModal(true);
						})
						.finally(() => {
							handleLoading(false);
						});
					break;
				case "whatsapp":
					userAuthAi(userPhoneNumber).then((res) => {
						mixpanel.identify(userPhoneNumber);
						mixpanel.people.set({
							$name: watch("name") || "Nome padrão",
							$email: watch("email") || "email padrão",
						});
						isNewBusiness
							? mixpanel.track("onb. Avançar Dados pessoais - Web Business")
							: mixpanel.track("onb. Inserir Telefone - Web Business");
						startBusinessState({ ...state, infoPhone: { linkWhatsApp: res.data.linkWhatsapp, typeAuthVerify } });
						navigate("/login/code", { ...state, infoPhone: { linkWhatsApp: res.data.linkWhatsapp, typeAuthVerify } });
					});
					break;
			}
		}
	};

	useEffect(() => {
		localStorage.setItem("logged", "false");
		fetchStoreVersion()
			.then((res) => {
				if (res.data && res.data[0].typeAuthVerify) {
					setTypeAuthVerify(res.data[0].typeAuthVerify);
				} else {
					setTypeAuthVerify("sms");
				}
			})
			.catch((err) => {
				setTypeAuthVerify("sms");
			});
	}, []);

	useEffect(() => {
		setValue("phone", "");
	}, [watch("ddi")]);

	return (
		<Container
			style={{ gap: "16px", flexWrap: "wrap" }}
			height={isNewBusiness() ? "auto" : null}
			minHeight={isNewBusiness() ? "600px" : null}>
			<div style={{ display: "flex", alignContent: "center", width: "100%", marginBottom: "1rem" }}>
				<AppBar progress={isNewBusiness() ? 25 : null} sendTo="/welcome" />
				<Chip help={true} />
			</div>

			<Text variant={"mid"}>
				{isNewBusiness() ? "Crie sua conta e comece a transformar seu negócio" : "Informe seu telefone"}
			</Text>

			<Dialog
				open={openModal}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 400 },
				}}>
				<Text>
					<strong className={classes.littlePurple}>{modalMessage}</strong>
				</Text>
				<Divider />
				<Button
					className={classes.littleBlue}
					style={{ padding: "15px", fontSize: "calc(0.5vw + 10px)" }}
					onClick={() => setOpenModal(false)}>
					OK
				</Button>
			</Dialog>

			{isNewBusiness() && (
				<div style={{ display: "flex", flexDirection: "column", alignContent: "stretch", width: "100%", gap: "16px" }}>
					<Inputs
						control={control}
						name="name"
						type="text"
						label="Nome completo"
						inputRef={fullNameRef}
						handleKeyDown={(e) => handleKeyDown(e, emailRef)}
					/>

					<Inputs
						control={control}
						name="email"
						type="email"
						label="E-mail"
						inputRef={emailRef}
						handleKeyDown={(e) => handleKeyDown(e, businessNameRef)}
					/>

					<Inputs
						control={control}
						name="businessName"
						type="text"
						label="Nome do seu negócio"
						inputRef={businessNameRef}
						handleKeyDown={(e) => handleKeyDown(e, telephoneRef)}
					/>
				</div>
			)}

			<div style={{ display: "flex", gap: "16px", width: "100%" }}>
				<Inputs control={control} name="ddi" type="ddi" className="selectFlag" />
				<Inputs
					control={control}
					name="phone"
					type="tel"
					label="Telefone"
					inputRef={telephoneRef}
					handleKeyDown={(e) => handleKeyDown(e, null)}
					placeholder="Ex: (11) 9 9300-0000"
					ddi={watch("ddi")}
					style={{ flex: 1, fontFamily: "Poppins" }}
					inputProps={{
						pattern: "[0-9]*",
						inputMode: "numeric",
					}}
				/>
			</div>

			<Text style={{ color: "#9C9C9C" }} align={"center"}>
				Enviaremos um código via SMS para validar seu número de telefone.
			</Text>

			<div style={{ flex: 1 }}></div>

			<GlobalButton
				title="Avançar"
				type="button"
				variant="contained"
				handleClick={() => {
					sendCode();
				}}
				disabled={!isValid}
				className={`btn-default ${!isValid ? "btn-color-seconday" : "btn-color-primary"}`}
				fullWidth={true}
			/>
		</Container>
	);
};

export default Login;
