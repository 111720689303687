import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { HoursSchema } from "helpers/schemas";

import businessService from "services/businessService";
import businessAction from "store/actions/businessAction";
import scheduleAction from "store/actions/scheduleAction";
import { useConfig } from "context/config.context";

import { Button, Header, Inputs, Text } from "components";

import "styles/openingHours/index.scss";
import "styles/openingHours/index.scss";

export default function OpeningHours() {
	const initialDaysList = [
		{
			id: 0,
			isChecked: false,
			name: "Domingo",
			intervals: [{ id: 1, start: "08:00", end: "18:00" }],
		},
		{
			id: 1,
			isChecked: false,
			name: "Segunda",
			intervals: [{ id: 1, start: "08:00", end: "18:00" }],
		},
		{
			id: 2,
			isChecked: false,
			name: "Terça",
			intervals: [{ id: 1, start: "08:00", end: "18:00" }],
		},
		{
			id: 3,
			isChecked: false,
			name: "Quarta",
			intervals: [{ id: 1, start: "08:00", end: "18:00" }],
		},
		{
			id: 4,
			isChecked: false,
			name: "Quinta",
			intervals: [{ id: 1, start: "08:00", end: "18:00" }],
		},
		{
			id: 5,
			isChecked: false,
			name: "Sexta",
			intervals: [{ id: 1, start: "08:00", end: "18:00" }],
		},
		{
			id: 6,
			isChecked: false,
			name: "Sábado",
			intervals: [{ id: 1, start: "08:00", end: "18:00" }],
		},
	];

	const [earlyBookings, setEarlyBookings] = useState([]);

	const { business } = useSelector((store) => store.business);

	const { handleFeedback, handleLoading, loading } = useConfig();

	const dispatch = useDispatch();

	const {
		control,
		handleSubmit,
		setValue,
		watch,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(HoursSchema.openingHoursSchema),
		defaultValues: {
			timelapse: "0",
			availability: "1|weeks",
			earlyBooking: "",
			daysList: initialDaysList,
		},
		mode: "onChange",
	});

	const watchDaysList = watch("daysList") || [];

	const isFormValid = () => {
		const daysList = watch("daysList");
		const hasErrors = errors.timelapse || errors.availability || errors.earlyBooking || errors.daysList || errors.root;

		return daysList?.some((day) => day.isChecked) && isValid && !hasErrors && !loading;
	};

	const validateMinutes = (data) => {
		const newEarlyBooking = [];

		data.map((earlyBooking) => {
			if (earlyBooking.minutes === 0)
				return newEarlyBooking.push({
					value: `${earlyBooking.id}|${earlyBooking.minutes}`,
					label: "Não há mínimo de antecedência",
				});

			if (earlyBooking.minutes > 0 && earlyBooking.minutes < 60)
				return newEarlyBooking.push({
					value: `${earlyBooking.id}|${earlyBooking.minutes}`,
					label: `${earlyBooking.minutes} Minutos`,
				});

			if (earlyBooking.minutes === 60)
				return newEarlyBooking.push({ value: `${earlyBooking.id}|${earlyBooking.minutes}`, label: "1 Hora" });

			return newEarlyBooking.push({
				value: `${earlyBooking.id}|${earlyBooking.minutes}`,
				label: `${earlyBooking.minutes / 60} Horas`,
			});
		});

		setEarlyBookings(newEarlyBooking);
	};

	const getEarlyBooking = async () => {
		const response = await businessService.getEarlyBookings();

		if (response && response.data) {
			validateMinutes(response.data);
		}
	};

	const getBusiness = async () => {
		handleLoading(true);
		const response = await businessService.getBusiness(business?.id);

		if (response && response.data) {
			setOpeningHours(business);
		}
		handleLoading(false);
	};

	const setOpeningHours = (business) => {
		const timelapse = business.timeLapse || 0;
		const availabilityTime = `${business.availabilityTime}|${business.availabilityTimeRef}`;
		const earlyBooking = business.earlyBooking
			? `${business.earlyBooking.id}|${business.earlyBooking.minutes}`
			: `${earlyBookings[0].value.split("|")[0]}|${earlyBookings[0].value.split("|")[1]}`;

		if (business.businessHours.length > 0) {
			populateBusinessHours(business.businessHours);
		} else {
			setValue("daysList", initialDaysList, {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});
		}
		setValue("timelapse", timelapse, { shouldValidate: true });
		setValue("availability", availabilityTime, { shouldValidate: true });
		setValue("earlyBooking", earlyBooking, { shouldValidate: true });
	};

	function formatToTime(isoString) {
		const date = new Date(isoString);
		const hours = date.getUTCHours().toString().padStart(2, "0");
		const minutes = date.getUTCMinutes().toString().padStart(2, "0");
		return `${hours}:${minutes}`;
	}

	const populateBusinessHours = (businessHours) => {
		const updatedDaysList = [...initialDaysList];

		updatedDaysList.forEach((day) => {
			const findDay = businessHours.filter((businessHour) => businessHour.weekday === day.id);
			if (findDay.length > 0) {
				day.isChecked = true;
				day.intervals = [];

				findDay.forEach((interval, index) => {
					if (index === 0) {
						day.intervals.push({
							id: 1,
							start: formatToTime(interval.openTime),
							end: formatToTime(interval.closeTime),
						});
					} else {
						day.intervals.push({
							id: index + 1,
							start: formatToTime(interval.openTime),
							end: formatToTime(interval.closeTime),
						});
					}
				});
			}
		});
		setValue("daysList", updatedDaysList, {
			shouldValidate: true,
			shouldDirty: true,
			shouldTouch: true,
		});
	};

	const onSubmit = async (data) => {
		handleLoading(true);
		try {
			const business_hours = [];

			data.daysList.forEach((day) => {
				if (day.isChecked) {
					day.intervals.forEach((interval) => {
						business_hours.push({
							weekday: day.id,
							open_time: interval.start,
							close_time: interval.end,
						});
					});
				}
			});

			const body = {
				business_hours,
				time_lapse: Number(data.timelapse),
				availability_time: Number(data.availability.split("|")[0]),
				availability_time_ref: data.availability.split("|")[1],
				early_booking_id: data.earlyBooking.split("|")[0],
				early_booking: data.earlyBooking.split("|")[1],
			};

			const response = await businessService.putBusinessHours(body, business.id);

			if (response.status === 200) {
				handleFeedback("Sucesso", "Horários atualizados com sucesso", "success");
				dispatch(scheduleAction.getBookingsProf());
				dispatch(businessAction.getBusiness(business?.id));
			}
		} catch (error) {
			console.log(error);
			handleFeedback("Erro", "Não foi possível atualizar, por favor, tente novamente.", "error");
		}
		handleLoading(false);
	};

	const handleCreateInterval = (dayId) => {
		handleLoading(true);
		const newDayList = watchDaysList.map((day) => {
			if (day.id === dayId) {
				const lastInterval = day.intervals[day.intervals.length - 1];
				const lastHour = lastInterval.end.split(":")[0];
				const lastId = day.intervals[day.intervals.length - 1].id + 1;

				const _newStartHour = parseInt(lastHour) + 1 >= 24 ? 0 : parseInt(lastHour) + 1;
				const _newEndHour = parseInt(lastHour) + 2 >= 24 ? 1 : parseInt(lastHour) + 2;

				const newDay = {
					...day,
					intervals: [
						...day.intervals,
						{
							id: lastId,
							start: `${putZero(_newStartHour)}:00`,
							end: `${putZero(_newEndHour)}:00`,
						},
					],
				};
				return newDay;
			}
			return day;
		});
		setValue("daysList", newDayList);
		handleLoading(false);
	};

	const putZero = (value) => {
		return value < 10 ? `0${value}` : value;
	};

	const handleRemoveInterval = (dayId, intervalIndex) => {
		handleLoading(true);
		const newDayList = watchDaysList.map((day) => {
			if (day.id === dayId) {
				return {
					...day,
					intervals: day.intervals.filter((_, index) => index !== intervalIndex),
				};
			}
			return day;
		});

		setValue("daysList", newDayList, {
			shouldValidate: true,
			shouldDirty: true,
			shouldTouch: true,
		});
		handleLoading(false);
	};

	useEffect(() => {
		getEarlyBooking();
	}, []);

	useEffect(() => {
		if (earlyBookings.length) {
			getBusiness();
		}
	}, [earlyBookings]);

	return (
		<>
			<Header title="Horário de Funcionamento" />
			<Divider />
			<div className="opening-hours">
				<div className="dynamic-hours">
					{watchDaysList?.length ? (
						watchDaysList.map((day) => (
							<div className="dynamic-hours-content" key={day.id}>
								<div className="input-form-dynamic">
									<div className="input-form-dynamic-control">
										<Inputs
											control={control}
											name={`daysList.${day.id}.isChecked`}
											type="checkbox"
											className="input-form-checkbox"
											checked={day.isChecked}
										/>
										<Text className="input-form-label-opening-hours">{day.name}</Text>
									</div>
									<div className="input-form-dynamic-fields">
										<Inputs
											control={control}
											name={`daysList.${day.id}.intervals.0.start`}
											type="time"
											className="input-form-text-interval"
											value={day.intervals[0].start || "08:00"}
											disabled={!day.isChecked}
										/>
										<Inputs
											control={control}
											name={`daysList.${day.id}.intervals.0.end`}
											type="time"
											className="input-form-text-interval"
											value={day.intervals[0].end || "18:00"}
											disabled={!day.isChecked}
										/>
									</div>
									<div className="input-form-dynamic-action">
										<button
											disabled={!day.isChecked}
											onClick={() => handleCreateInterval(day.id)}
											className="input-form-dynamic-button">
											{day.isChecked && "+"}
										</button>
									</div>
								</div>
								{/* Additional intervals */}
								{day.intervals.map((interval, index) => {
									if (index === 0) return null;
									return (
										<div key={interval.id} className="input-form-dynamic">
											<div className="input-form-dynamic-control" />
											<div className="input-form-dynamic-fields-secondary">
												<Inputs
													control={control}
													type="time"
													name={`daysList.${day.id}.intervals.${index}.start`}
													disabled={!day.isChecked}
													className="input-form-text-interval"
													value={interval.start}
												/>
												<Inputs
													control={control}
													type="time"
													name={`daysList.${day.id}.intervals.${index}.end`}
													disabled={!day.isChecked}
													className="input-form-text-interval"
													value={interval.end}
												/>
											</div>
											<div className="input-form-dynamic-action">
												<button
													type="button"
													disabled={!day.isChecked}
													onClick={() => handleRemoveInterval(day.id, index)}
													className="input-form-dynamic-remove-button">
													{day.isChecked && "-"}
												</button>
											</div>
										</div>
									);
								})}
							</div>
						))
					) : (
						<div className="dynamic-hours-content-placeholder">
							<Text className="date-header-title">Carregando...</Text>
						</div>
					)}
					<div className="button-container-opening-hours">
						<div style={{ width: "90%", marginTop: "10px" }}>
							<Button
								title="Salvar"
								type="button"
								className="btn-color-primary"
								variant="contained"
								disabled={!isFormValid()}
								fullWidth={true}
								handleClick={handleSubmit(onSubmit)}
							/>
						</div>
					</div>
				</div>
				<div className="configuration-hours">
					<Inputs
						control={control}
						type="select"
						name="timelapse"
						label="Mostrar meus horários com intervalos de:"
						options={HoursSchema.timeLapseList}
						className="input-form-text-opening-hours"
						error={errors.timelapse}
					/>
					<Inputs
						control={control}
						type="select"
						name="availability"
						label="Período de disponibilidade:"
						options={HoursSchema.availabilityTimeList}
						className="input-form-text-opening-hours"
						error={errors.availability}
					/>
					<Inputs
						control={control}
						type="select"
						name="earlyBooking"
						label="Agendar com antecedência de no mínimo:"
						options={earlyBookings}
						className="input-form-text-opening-hours"
						error={errors.earlyBooking}
					/>
				</div>
				<div className="button-container-opening-hours-mobile">
					<Button
						title="Salvar"
						type="button"
						color="primary"
						variant="contained"
						disabled={!isFormValid()}
						fullWidth={true}
						handleClick={handleSubmit(onSubmit)}
					/>
				</div>
			</div>
		</>
	);
}
