import { CircularProgress } from "@mui/material";
import React from "react";

const GradientLoading = () => {
	return (
		<React.Fragment>
			<svg width={0} height={0}>
				<defs>
					<linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
						<stop offset="0%" stopColor={process.env.REACT_APP_SECOND_COLOR} />
						<stop offset="100%" stopColor="#1CB5E0" />
					</linearGradient>
				</defs>
			</svg>
			<CircularProgress
				sx={{
					"svg circle": { stroke: "url(#my_gradient)" },
				}}
				size={40}
			/>
		</React.Fragment>
	);
};

export default GradientLoading;
