import * as Yup from "yup";
import moment from 'moment';

const untilRuleTwoValues = new Array(40)
  .fill(null)
  .map((_, index) => (index + 1).toString());

export const repeatSchedule = Yup.object({
  frequency: Yup.string().required("Este campo é obrigatório.").oneOf(['1', '7', '14', '15','20','21','Mensalmente'], "Selecione um valor válido."),
  rule: Yup.string().required("Este campo é obrigatório.").oneOf(['Depois de', 'Na data selecionada'], "Selecione um valor válido."),
  ruleTwo: Yup.string().required("Este campo é obrigatório.").oneOf(untilRuleTwoValues, "Selecione um valor válido."),
  selectedDate: Yup.date()
    .nullable()
    .transform((currentValue, originalValue) => {
      return originalValue === "" ? null : currentValue;
    })
    .required("Esse campo é obrigatório")
});

export const initialRepeatScheduleForm = {
  frequency: 1,
  rule: 'Depois de',
  ruleTwo: 1,
  selectedDate: moment().format('YYYY-MM-DD')
};