import React from "react";

import Login from "./content/login";

const LoginPage = () => {
	
	return <Login />;
};

export default LoginPage;
