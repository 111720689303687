import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Menu } from "@material-ui/icons";

import { useBusiness } from "context/business.context";

import { isMobile } from "utils/isMobile";
import { Button, Text } from "components";

import "./styles.scss";

const PageHeader = ({ title, showNotifications = true }) => {
	const { toggleMenu } = useBusiness();

	return (
		<header className="header-container">
			{isMobile() && <Button type="icon" icon={<Menu />} className="icon-menu-custom" handleClick={toggleMenu} />}
			<Text>{title}</Text>
			{showNotifications && <NotificationsNoneIcon className="icon-notification" />}
		</header>
	);
};

export default PageHeader;
