import * as React from "react";
import { Dialog as DialogDefault } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useConfig } from "context/config.context";

import "./style.scss";

function GradientCircularProgress() {
	const secondColor = process.env.REACT_APP_SECOND_COLOR;
	return (
		<React.Fragment>
			<svg width={0} height={0}>
				<defs>
					<linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
						<stop offset="0%" stopColor={secondColor} />
						<stop offset="100%" stopColor="#1CB5E0" />
					</linearGradient>
				</defs>
			</svg>
			<CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
		</React.Fragment>
	);
}
export default function Loading() {
	const { loading } = useConfig();

	return (
		<DialogDefault
			open={loading}
			fullScreen
			PaperProps={{
				style: { backgroundColor: "rgba(0, 0, 0, 0.6)" },
			}}>
			<div className="loading-content">
				<GradientCircularProgress />
				<span>Processando...</span>
			</div>
		</DialogDefault>
	);
}
