import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { CloseOutlined } from "@material-ui/icons";
import { Dialog, Typography } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ModalServiceSchema } from "helpers/schemas";

import { Button } from "components";
import { moneyRender } from "utils/utils";

import "./style.css";

const minutesData = _.times(60, (num) => `${num}m`);
const vectorHoursData = [...Array(24).keys(), ...[...Array(31)].map((__, i) => 24 * i).splice(1, 30)];
const hoursData = vectorHoursData.map((i) => `${i}h`);

function formatMinutesToHourMinute(totalMinutes) {
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;
	return { hours, minutes };
}

export default function ModalServicesEdit({ open = false, close = () => {}, item = null, handleChange = () => {} }) {
	const { business } = useSelector((store) => store.business);

	const priceTypes = useSelector((state) => state.priceTypes.priceTypeList);

	const [formattedPrice, setFormattedPrice] = useState("R$ 0,00");

	const { handleSubmit, register, setValue } = useForm({
		resolver: yupResolver(ModalServiceSchema.businessProfileSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: ModalServiceSchema.initialBusinessForm,
	});

	const handleUpdate = (data) => {
		const priceFormatt = parseInt(data.price.replace(/[^0-9]/g, ""));
		const hours = parseInt(data.hours.slice(0, -1));
		const minutes = parseInt(data.minutes.slice(0, -1));
		const totalMinutes = hours * 60 + minutes;
		handleChange(
			{
				...item,
				service: {
					...item.service,
					price: priceFormatt,
					duration: totalMinutes,
					pricingTypeId: data.price_type,
				},
			},
			true
		);
	};

	const handleClose = () => {
		close();
	};

	const handlePriceChange = (event) => {
		const value = event.target.value;
		const numericValue = parseInt(value.replace(/[^0-9]/g, ""));
		const formatted = moneyRender(business, numericValue / 100);
		setFormattedPrice(formatted);
		setValue("price", numericValue);
	};

	useEffect(() => {
		if (item) {
			const { hours, minutes } = formatMinutesToHourMinute(item.service.duration);
			setValue("price", moneyRender(business, item.service.price / 100));
			setValue("hours", `${hours}h`);
			setValue("minutes", `${minutes}m`);
			setValue("price_type", item.service.pricingTypeId);
		}
	}, [item]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperProps={{ style: { borderRadius: 12 } }}
			sx={{
				"& .MuiDialog-container": {
					"& .MuiPaper-root": {
						width: "100%",
						maxWidth: "500px", // Set your width here
					},
				},
			}}>
			<div style={{ padding: "14px" }}>
				<div style={{ display: "flex", justifyContent: "space-between", paddingLeft: "8px" }}>
					<Typography style={{ color: "#3E3E3E", fontSize: "18px", fontWeight: "bold" }}>
						Detalhes do Serviço
					</Typography>
					<CloseOutlined style={{ width: "30px", color: "#616161", cursor: "pointer" }} onClick={handleClose} />
				</div>

				<div className="form-service">
					<div style={{ display: "flex", alignItems: "center" }}>
						<div className="input-form-service">
							<Typography className="input-form-label">Horas</Typography>
							<select {...register("hours")} className="input-form">
								{hoursData.map((hour) => (
									<option key={hour} value={hour}>
										{hour}
									</option>
								))}
							</select>
						</div>
						<div className="input-form-service">
							<Typography className="input-form-label">Minutos</Typography>
							<select {...register("minutes")} className="input-form">
								{minutesData.map((minute) => (
									<option key={minute} value={minute}>
										{minute}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="input-form-service">
						<Typography className="input-form-label">Tipo de preço</Typography>
						<select {...register("price_type")} className="input-form">
							{priceTypes.map((priceType) => (
								<option key={priceType.id} value={priceType.id}>
									{priceType.name}
								</option>
							))}
						</select>
					</div>
					<div className="input-form-service">
						<Typography className="input-form-label">Preço</Typography>
						<input
							{...register("price")}
							className="input-form input-form-input"
							type="text"
							value={formattedPrice}
							onChange={handlePriceChange}
						/>
					</div>
					<Button
						type="button"
						handleClick={handleSubmit(handleUpdate)}
						title="Salvar"
						variant="contained"
						fullWidth={true}
						className="btn-color-primary btn-default"
					/>
				</div>
			</div>
		</Dialog>
	);
}
