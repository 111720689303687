import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import React from "react";

import logo from "assets/images/prit.png";
import logoZDoctors from "assets/images/zdoctors.png";
import { Text } from "components";
import { getAppName } from "utils/validators";

const useStyles = makeStyles((theme) => ({
	appBar: {
		backgroundColor: "#ffffff",
		display: "flex",
		justifyContent: "space-between",
		width: "100vw",
	},
	title: {
		flexGrow: 1,
		textAlign: "center",
		color: "#393762",
		marginRight: "6%",
	},
	logo: {
		maxWidth: "100px",
		height: "auto",
		[theme.breakpoints.down("sm")]: {
			maxWidth: "80px",
		},
	},
}));

function HideOnScroll(props) {
	const { children } = props;
	const trigger = useScrollTrigger();

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

export default function HeaderWelcome(props) {
	const classes = useStyles();

	return (
		<React.Fragment>
			<HideOnScroll {...props}>
				<AppBar className={classes.appBar}>
					<Toolbar style={{ minHeight: "80px" }}>
						<img src={getAppName() === "Prit" ? logo : logoZDoctors} alt="Logo" className={classes.logo} />
						<Text variant="title" className={classes.title}>
							Seja bem-vindo ao {getAppName()}!
						</Text>

					</Toolbar>
				</AppBar>
			</HideOnScroll>
			<Toolbar />
		</React.Fragment>
	);
}
