import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useMixpanel } from "react-mixpanel-browser";
import { getAuth, updateEmail, updateProfile } from "firebase/auth";
import { Button, Dialog, TextField, Typography } from "@mui/material";

import { useConfig } from "context/config.context";

import { ButtonCommom } from "components";
import { termsOfUse } from "utils";

import useStyles from "./style-personal-data-page";

const PerosnalData = () => {
	const auth = getAuth();
	const classes = useStyles();
	const navigate = useNavigate();
	const mixpanel = useMixpanel();
	const { handleFeedback } = useConfig();

	const { me } = useSelector((state) => state.me);

	const [name, setName] = useState("");

	const [email, setEmail] = useState("");

	const [openModal, setOpenModal] = useState("");

	const removeNumbers = (str) => {
		for (var i = 0; i < 10; i++) {
			str = str.replaceAll(i + "", "");
		}
		return str;
	};

	return (
		<Fragment>
			<Dialog open={openModal} onClick={() => setOpenModal(false)}>
				<Typography style={{ marginTop: "30px", color: "#3689ea", fontSize: "calc(1vw + 9px)", textAlign: "center" }}>
					TERMO DE USO E CREDENCIAMENTO DE PAGAMENTOS ONLINE
				</Typography>
				<Typography
					style={{
						justifyContent: "center",
						padding: "0 30px",
						fontSize: "calc(0.5vw + 9px)",
						whiteSpace: "pre-line",
					}}>
					{termsOfUse(30)}
				</Typography>
			</Dialog>

			<div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginTop: "30px" }}>
				{/* <ArrowBackIosRounded
						style={{ paddingTop: "9px", float: "left", width: "35px", height: "35px" }}
						className={classes.icon}
						onClick={() => {
							navigate("/login");
						}}
					/> */}

				<Typography style={{ fontSize: "calc(3vw + 10px)", alignSelf: "center" }}>
					<strong className={classes.title}>Informe seus dados pessoais</strong>
				</Typography>

				<Typography style={{ marginTop: "120px", alignSelf: "center" }}>
					{name.length > 0 && name.replaceAll(" ", "").length === 0 && (
						<h1 className={classes.error}>O nome inserido não é valído.</h1>
					)}
				</Typography>

				<TextField
					value={name}
					variant="standard"
					style={{
						marginTop: "5px",
						maxWidth: "500px",
						width: "90%",
						fontSize: "calc(2vw + 9px)",
						alignSelf: "center",
					}}
					className={classes.field}
					label="Nome completo"
					onChange={(e) => {
						setName(removeNumbers(e.target.value));
					}}
				/>

				<br></br>

				<Typography style={{ marginTop: "50px", alignSelf: "center" }}>
					{email.length > 0 && !email.includes("@") && (
						<h1 className={classes.error}>O e-mail inserido não é valído.</h1>
					)}
				</Typography>

				<TextField
					value={email}
					variant="standard"
					style={{
						marginTop: "5px",
						maxWidth: "500px",
						width: "90%",
						fontSize: "calc(2vw + 9px)",
						alignSelf: "center",
					}}
					className={classes.field}
					label="E-mail"
					onChange={(e) => {
						setEmail(e.target.value);
					}}
				/>

				<Typography style={{ marginTop: "150px", marginBottom: "30px", alignSelf: "center", textAlign: "center" }}>
					<strong className={classes.message} style={{ fontSize: "calc(0.5vw + 10px)" }}>
						Ao avançar você está de acordo com nossos{" "}
						<Button
							className={classes.Button}
							style={{ fontSize: "calc(0.5vw + 10px)" }}
							onClick={() => setOpenModal(true)}>
							Termos de uso
						</Button>
					</strong>
				</Typography>

				<div style={{ display: "flex", justifyContent: "center" }}>
					<ButtonCommom
						style={{ alignSelf: "center" }}
						disable={!email.includes("@") || name.replaceAll(" ", "").length === 0}
						onClick={() => {
							updateProfile(auth.currentUser, {
								displayName: name,
							})
								.then(() => {
									updateEmail(auth.currentUser, email)
										.then(() => {
											navigate("/welcome", { name: name });
											mixpanel.identify(me.phone ?? me.phoneUnverified);
											mixpanel.track("Cadastro de Conta - Web Business");
										})
										.catch(() => {
											handleFeedback("Erro", "Erro ao atualizar o e-mail", "error");
										});
								})
								.catch(() => {
									handleFeedback("Erro", "Ocorreu um erro ao atualizar os dados, tente novamente.", "error");
								});
						}}
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default PerosnalData;
