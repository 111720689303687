import React from "react";
import { Provider } from "react-redux";
import { MixpanelProvider } from "react-mixpanel-browser";
import { PersistGate } from "redux-persist/integration/react";

import { persistor, store } from "../store";
import Router from "../routes/routes";

import "../style.css";
import "../styles/global/default.scss";

export default function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<MixpanelProvider>
					<div className="App">
						<Router />
					</div>
				</MixpanelProvider>
			</PersistGate>
		</Provider>
	);
}
