export const isNullish = (value) => value === null || value === undefined;

export const validateCPF = (cpf) => {
	cpf = cpf.replace(/[^\d]+/g, "");
	if (cpf === "") return false;

	let Soma;
	let Resto;
	Soma = 0;
	if (
		cpf === "00000000000" ||
		cpf === "11111111111" ||
		cpf === "22222222222" ||
		cpf === "33333333333" ||
		cpf === "44444444444" ||
		cpf === "55555555555" ||
		cpf === "66666666666" ||
		cpf === "77777777777" ||
		cpf === "88888888888" ||
		cpf === "99999999999"
	)
		return false;

	for (let i = 1; i <= 9; i++) Soma = Soma + Number.parseInt(cpf.substring(i - 1, i)) * (11 - i);
	Resto = (Soma * 10) % 11;

	if (Resto === 10 || Resto === 11) Resto = 0;
	if (Resto !== Number.parseInt(cpf.substring(9, 10))) return false;

	Soma = 0;
	for (let i = 1; i <= 10; i++) Soma = Soma + Number.parseInt(cpf.substring(i - 1, i)) * (12 - i);
	Resto = (Soma * 10) % 11;

	if (Resto === 10 || Resto === 11) Resto = 0;
	if (Resto !== Number.parseInt(cpf.substring(10, 11))) return false;
	return true;
};

export const validateEmail = (value) => {
	if (isNullish(value)) return false;
	const usuario = value.substring(0, value.indexOf("@"));
	const dominio = value.substring(value.indexOf("@") + 1, value.length);

	if (
		usuario.length >= 1 &&
		dominio.length >= 3 &&
		usuario.search("@") === -1 &&
		dominio.search("@") === -1 &&
		usuario.search(" ") === -1 &&
		dominio.search(" ") === -1 &&
		dominio.search(".") !== -1 &&
		dominio.indexOf(".") >= 1 &&
		dominio.lastIndexOf(".") < dominio.length - 1
	) {
		return true;
	}
	return false;
};

export const validaCNPJ = (cnpj) => {
	cnpj = cnpj.replace(/[^\d]+/g, "");
	if (cnpj == "" || cnpj.length != 14 || /^(\d)\1{13}$/.test(cnpj)) return false;

	// Valida DVs
	let tamanho = cnpj.length - 2;
	let numeros = cnpj.substring(0, tamanho);
	const digitos = cnpj.substring(tamanho);
	let soma = 0;
	let pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2) pos = 9;
	}
	let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado != digitos.charAt(0)) return false;

	tamanho = tamanho + 1;
	numeros = cnpj.substring(0, tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--) {
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2) pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
	if (resultado != digitos.charAt(1)) return false;
	return true;
};

export const getAppName = (pay = false) => {
	const appName = process.env.REACT_APP_NAME;
	switch (appName) {
		case "ZDoctors":
			return pay ? "Z Pay" : "Z Doctors";
		case "Prit":
			return pay ? "Prit Pay" : "Prit";
		default:
			return pay ? "Prit Pay" : "Prit";
	}
};
