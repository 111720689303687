import React from "react";
import { Divider } from "@material-ui/core";

import { usePayment } from "context/payment.context";

import ProfilePritPlan from "containers/schedulePlan";
import Address from "containers/schedulePlan/addressPlan";
import PaymentMethod from "containers/schedulePlan/paymentMethod";
import CreditCard from "containers/schedulePlan/creditCardPayment";
import PixQrCode from "containers/schedulePlan/pixQrCode";
import Pix from "containers/schedulePlan/pix";
import ActualPlan from "containers/schedulePlan/actualPlan";

import { Header } from "components";

const SchedulePlanPages = () => {
	const { type } = usePayment();
	const selectPage = () => {
		switch (type) {
			case "ActualPlan":
				return <ActualPlan />;
			case "PritPlan":
				return <ProfilePritPlan />;
			case "PaymentMethod":
				return <PaymentMethod />;
			case "AddressPlan":
				return <Address />;
			case "CreditCardPayment":
				return <CreditCard />;
			case "Pix":
				return <Pix />;
			case "PixQrCode":
				return <PixQrCode />;
			default:
				return <ActualPlan />;
		}
	};

	return (
		<>
			<Header title="Assinatura" />
			<Divider />
			{selectPage()}
		</>
	);
};

export default SchedulePlanPages;
