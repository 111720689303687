import React from "react";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useConfig } from "context/config.context";

import "./style.scss";

const Feedback = () => {
	const { feedback, closeFeedback } = useConfig();

	return (
		feedback.open && (
			<div className="box-feedback">
				<Alert
					severity={feedback.severity}
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								closeFeedback(false);
							}}>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					className="feedback">
					<AlertTitle>{feedback.title}</AlertTitle>
					{feedback.message}
				</Alert>
			</div>
		)
	);
};

export default Feedback;
