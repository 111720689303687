import React, { Fragment, useEffect, useState } from "react";
import { Box, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";

import bookingTransactionService from "services/bookingTransactionService.js";
import { useConfig } from "context/config.context";

import { GradientLoading, Text } from "components";

import "styles/eventModal/index.scss";

const ScheduleBreakModal = ({ onClose, breakData }) => {
	const [loading, setLoading] = useState(false);

	const { handleFeedback } = useConfig();

	useEffect(() => {
		const timeout = setTimeout(() => {
			setLoading(false);
		}, 2000);

		return () => clearTimeout(timeout);
	}, []);

	const handleCancel = async () => {
		try {
			setLoading(true);
			const response = await bookingTransactionService.deleteScheduleBreak(breakData.id);
			if (response.status === 204) {
				handleFeedback("Sucesso", "Bloqueio de horário excluído com sucesso.", "success");
				onClose();
				window.location.reload();
			}
		} catch (error) {
			handleFeedback("Erro", "Ocorreu um erro inesperado, tente novamente mais tarde.", "error");
			console.error(error);
		}
	};

	const calculateDuration = () => {
		const start = moment(breakData.start);
		const end = moment(breakData.end);
		const duration = moment.duration(end.diff(start));
		const hours = Math.floor(duration.asHours());
		const minutes = duration.minutes();
		return `${hours} hora(s) e ${minutes} minuto(s)`;
	};

	const formatDateTime = (date) => {
		return moment(date).format("DD/MM/YYYY [às] HH:mm");
	};

	if (loading) {
		return (
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					width: "100vw",
					height: "100vh",
					backgroundColor: "rgba(0, 0, 0, 0.8)",
					zIndex: 999,
				}}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<GradientLoading />
				</Box>
			</div>
		);
	}

	return (
		<Fragment>
			<Modal open={true} onClose={onClose} aria-labelledby="Detalhes do Bloqueio" className="containerModal">
				<Box className="modal">
					<div className="headerBreakModal">
						<div>
							<Close onClick={onClose} style={{ fontSize: "1.5rem", cursor: "pointer" }} />
						</div>
						<div className="title">
							<Text>Detalhes do Bloqueio</Text>
						</div>
					</div>
					<section className="breakData">
						<div className="alignData">
							<Text>Profissional:</Text>
							<Text>{breakData.professionalName}</Text>
						</div>
						<div className="alignData">
							<Text>Data de Início:</Text>
							<Text>{formatDateTime(breakData.start)}</Text>
						</div>
						<div className="alignData">
							<Text>Data de Término:</Text>
							<Text>{formatDateTime(breakData.end)}</Text>
						</div>
						<div className="alignData">
							<Text>Duração do bloqueio:</Text>
							<Text>{calculateDuration()}</Text>
						</div>
					</section>
					<div>
						<button className="cancelButton" style={{ cursor: "pointer" }} onClick={handleCancel}>
							<Text style={{ color: "White" }}>Excluir</Text>
						</button>
					</div>
				</Box>
			</Modal>
		</Fragment>
	);
};

export default ScheduleBreakModal;
