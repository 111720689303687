import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, TextField } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { ArrowBackIos } from "@material-ui/icons";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { useServicesContext } from ".";
import { useConfig } from "context/config.context";
import professionalServiceService from "services/ServicesService";

import { moneyRender } from "utils/utils";
import { ButtonCore, Container } from "components";

import "swiper/css";
import "styles/selectServiceDialog/style.scss";

const SelectServiceDialog = ({professionalID, professionalCategoriesID, ...props}) => {
	const { contextServices, setContextServices } = useServicesContext();
	const { business } = useSelector((store) => store.business);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const { sector } = useSelector((store) => store.sector);
	const [editPriceDialogOpen, setEditPriceDialogOpen] = useState(false);
	const [professionalCategories, setProfessionalCategories] = useState([]);
	const [selectedService, setSelectedService] = useState(null);
	const [newPrice, setNewPrice] = useState("");

	const { setLoading } = useConfig(false);

	useEffect(() => {
		if (professionalCategories?.length > 0) {
			const defaultValue = selectedCategory || professionalCategories[0];
			setSelectedCategory(defaultValue);

			if (professionalID && defaultValue) {
				setLoading(true);
				const fetchProfessionalServices = async () => {
					try {
						const response = await professionalServiceService.getProfessionalServices(professionalID, defaultValue.id);
						const servicesWithCategory = response.data.map((service)=> ({
							...service,
							categoryId: selectedCategory.id
						}));
						setContextServices((prev)=>({
							...prev,
							[selectedCategory.id]: servicesWithCategory,
						}));
					}catch (error) {
						console.error('Erro ao buscar serviço dos profissionais', error);
					}finally {
						setLoading(false);
					}
				};
				fetchProfessionalServices();
			}
		}
	},[selectedCategory]);

	useEffect(()=>{
		handleProfessionalCategories();
	},[]);

	const handleEditPrice = (service) => {
		setSelectedService(service);
		setNewPrice(moneyRender(business, service.price / 100));
		setEditPriceDialogOpen(true);
	};

	const handleUpdatePrice = () => {
		const rawPrice = parseFloat(newPrice.replace(/[^\d]/g, "")) / 100;

		setContextServices((prev) => ({
			...prev,
			[selectedService.categoryId]: prev[selectedService.categoryId].map((s) =>
				s.id === selectedService.id ? { ...s, price: Math.round(rawPrice * 100) } : s
			),
		}));

		setEditPriceDialogOpen(false);
	};

	const handleProfessionalCategories = () => {
    try {
      const businessCategories = sector[0].categories;
      const selectedProfessional = professionalCategoriesID;
      const filteredCategories = businessCategories.filter(category =>
        selectedProfessional.includes(category.id)
      );
      setProfessionalCategories(filteredCategories);

      if (filteredCategories.length > 0) {
        setSelectedCategory(filteredCategories[0]);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

	return (
		<Container style={{ gap: "16px" }} transparent>
			<div style={{ display: "flex" }}>
				<ArrowBackIos style={{ width: "30px", color: " #616161", cursor: "pointer" }} onClick={props.onClose} />
			</div>
			<div className="categoriesTitle">
				<p>Selecione uma categoria:</p>
			</div>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", gap: "1rem" }}>
				<Swiper spaceBetween={10} slidesPerView="auto" style={{ padding: "10px 0", width: "100%" }}>
					{professionalCategories?.length > 0 &&
						professionalCategories?.map((category) => (
							<SwiperSlide key={category.id} style={{ width: "auto", paddingBottom: "50px" }}>
								<Button
									onClick={() => setSelectedCategory(category)}
									variant="outlined"
									style={{
										backgroundColor: selectedCategory?.id === category?.id ? "#3688EB" : "",
										color: selectedCategory?.id === category?.id ? "#FFFFFF" : "#3E3E3E",
										borderRadius: "8px",
										textTransform: "none",
										fontSize: "16px",
									}}>
									{category.name}
								</Button>
							</SwiperSlide>
						))}
				</Swiper>

				<div className="servicesTitle">
					<p>Selecione um serviço:</p>
				</div>
				<div className="servicesGrid">
					<p>Nome</p>
					<p>Tempo</p>
					<p>Preço</p>
				</div>

				{selectedCategory && contextServices[selectedCategory.id]?.length > 0 ? (
					<div className="servicesDadContainer">
						{contextServices[selectedCategory.id].map((service) => (
							<div key={service.service.id}>
								<div
									onClick={() => {
										props.select(service);
										props.onClose();
									}}>
									<div className="servicesContainer">
									<p>{service.service.name}</p>
									<p>{service.service.duration + "m"}</p>
										<p
											style={{
												color: "#3689ea",
												fontSize: "16px",
												cursor: "pointer",
											}}
											onClick={(e) => {
												e.stopPropagation();
												handleEditPrice(service);
											}}>
											{moneyRender(business, service?.price / 100)}
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
				) : (
					<div className="captionContainer">
						<p>Não há serviços para categoria selecionada</p>
					</div>
				)}
			</div>

			<Dialog
				open={editPriceDialogOpen}
				onClose={() => setEditPriceDialogOpen(false)}
				PaperProps={{ style: { borderRadius: 12 } }}>
				<div style={{ padding: "16px" }}>
					<div style={{ display: "flex", alignItems: "center" }}>
						<ArrowBackIos
							style={{ width: "30px", color: "#616161", cursor: "pointer" }}
							onClick={() => setEditPriceDialogOpen(false)}
						/>
						<Typography
							style={{
								color: "#3E3E3E",
								fontSize: "18px",
								fontWeight: "bold",
								marginBottom: "8px",
								marginLeft: "8px",
							}}>
							Editar valor do serviço
						</Typography>
					</div>

					<DialogContent>
						<TextField
							label="Novo Preço"
							type="text"
							value={newPrice}
							onChange={(e) => {
								const rawValue = e.target.value.replace(/[^\d]/g, "");
								setNewPrice(moneyRender(business, parseFloat(rawValue) / 100 || 0));
							}}
							onBlur={(e) => {
								const rawValue = e.target.value.replace(/[^\d]/g, "");
								setNewPrice(moneyRender(business, parseFloat(rawValue) / 100 || 0));
							}}
							fullWidth
							variant="outlined"
						/>
					</DialogContent>
					<DialogActions>
						<ButtonCore onClick={handleUpdatePrice} text="Salvar" color="#3689ea" />
					</DialogActions>
				</div>
			</Dialog>
		</Container>
	);
};

export default SelectServiceDialog;
