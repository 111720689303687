import React, { useEffect, useState } from "react";

const CoreProgressBar = ({ progress }) => {
	const [animatedProgress, setAnimatedProgress] = useState(0);

	const secondColor = process.env.REACT_APP_SECOND_COLOR;
	const secondColorRgb = process.env.REACT_APP_SECOND_COLOR_RGB;

	useEffect(() => {
		const timeout = setTimeout(() => {
			setAnimatedProgress(progress); // Anima o progresso
		}, 100);
		return () => clearTimeout(timeout);
	}, [progress]);

	const containerStyle = {
		width: "100%",
		maxWidth: "230px",
		height: "15px",
		backgroundColor: "#e0e0de",
		borderRadius: "5px",
		overflow: "hidden",
		boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 1px",
	};

	const fillerStyle = {
		height: "100%",
		width: `${animatedProgress}%`,
		background: `linear-gradient(90deg, #39adc4 0%, ${secondColor} 100%)`,
		transition: "width 0.5s ease-in-out",
		boxShadow: `0px 0px 10px rgba(${secondColorRgb}, 0.8)`,
	};

	const glowEffect = {
		height: "100%",
		width: "100%",
		background: `radial-gradient(circle, rgba(${secondColorRgb}, 0.3) 30%, transparent 80%)`,
		animation: "pulse 1.5s infinite",
	};

	return (
		<div style={containerStyle}>
			<div style={fillerStyle}></div>
			<div style={glowEffect}></div>
		</div>
	);
};

export default CoreProgressBar;
