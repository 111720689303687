import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Text from '../texts';
import EditBooking from '../../views/editBooking';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Dialog } from '@material-ui/core';
import { moneyMask } from '../../utils/utils';
import './style.scss';

const ProfessionalDetailsComponent = ({  booking, onDelete, onUpdate }) => {

  const [isEditing, setIsEditing] = useState(false);

  const { business } = useSelector((store)=> store.business);

  const formatDateTime = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
  
    const monthNames = ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'];
    const monthAbbrev = monthNames[startDate.getMonth()];
  
    const day = startDate.getDate().toString().padStart(2, '0');
    const month = (startDate.getMonth() + 1).toString().padStart(2, '0');
    const dateFormatted = `${day}/${month}`;
  
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    const startTimeFormatted = startDate.toLocaleTimeString('pt-BR', timeOptions);
    const endTimeFormatted = endDate.toLocaleTimeString('pt-BR', timeOptions);
    const timeFormatted = `${startTimeFormatted} - ${endTimeFormatted}`;
  
    return {
      monthAbbrev,
      dateFormatted,
      timeFormatted,
    };
  };

  const { monthAbbrev, dateFormatted, timeFormatted } = formatDateTime(
    booking?.start_time,
    booking?.end_time
  );

  return (
    <div className='componentContainer'>
      <div className='serviceData'>
        <Text  variant={'body1'} style={{color:"#fff"}}>{monthAbbrev}</Text>
        <Text  variant={'body1'} style={{color:"#fff"}}>{dateFormatted}</Text>
        <Text  variant={'body1'} style={{color:"#fff"}}>{timeFormatted}</Text>
      </div>

      <div className='serviceDetails'>
        <Text style={{color: "#0B192C"}}>{booking?.service?.service?.name}</Text>
        <Text variant={'body1'}>com {booking?.professional?.name}</Text>

        <div className='serviceInfo'>
          <div>
            <AccessTimeIcon style={{width:"16px", color: "#616161"}}/>
            <Text variant={'body1'}>{booking.service.duration} min</Text>
          </div>
          <Text style={{color:"#3688EB", fontWeight: "bold"}}variant={'body1'}>{moneyMask(booking?.service?.price, business?.currency)}</Text>
        </div>

      </div>

      <section className='actionContainer'>
        <Text style={{color: '#3688EB'}} onClick ={()=> setIsEditing(true)}>Editar</Text>
        <Text style={{color: '#8E1616'}} onClick = {onDelete} >Excluir</Text>
      </section>

      <Dialog
      open={isEditing}
      fullScreen
			PaperProps={{
				style: { backgroundColor: "transparent" },
			}}
      >
        <EditBooking 
          onClose={()=> setIsEditing(false)}  
          booking={booking}
          onSave={(updatedBooking) => {
            onUpdate(updatedBooking);
            setIsEditing(false);
          }}
        />
      </Dialog>
    </div>
  );
};

export default ProfessionalDetailsComponent;
