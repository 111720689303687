import React, { useEffect, useState } from "react";
import Inputs from "../../../../components/inputs";
import Text from "../../../../components/texts";
import { Button, Dialog, TextField } from "@material-ui/core";
import { MenuItem } from "@mui/material";
import { ArrowForwardIos } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import "./styles.scss";
import { useSelector } from "react-redux";
import SelectServiceDialog from "../dialog-select-service";
import { moneyMask } from "../../../../utils/utils";
import { useConfig } from "context/config.context";
import GlobalButton from "../../../../components/buttons";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "@mui/material/Select";

const AddServiceScheduleDialog = ({
	alterView = () => {},
	servicesSelected,
	editOtherService,
	clearForm = () => {},
}) => {
	const { business } = useSelector((store) => store.business);
	const [openServiceDialog, setOpenServiceDialog] = useState(false);
	const [professional, setProfessional] = useState(editOtherService?.professional ?? null);
	const [service, setService] = useState("");
	const { handleFeedback } = useConfig();

	const dados = Yup.object({
		date: Yup.string().required("Data obrigatória"),
		time: Yup.string().required("Horário obrigatório"),
	});

	const {
		handleSubmit,
		control,
		setValue,
		trigger,
		formState: { isValid },
	} = useForm({
		defaultValues: {
			date: null,
			time: null,
		},
		resolver: yupResolver(dados),
		mode: "onChange",
	});

	const onSubmit = (data) => {
		const newItem = {
			date: data.date,
			time: data.time,
			professional: professional,
			service: service,
		};

		if (editOtherService) {
			const updatedServices = [...servicesSelected];
			updatedServices[editOtherService.indice] = {
				...newItem,
				service: { ...newItem.service },
				professional: { ...newItem.professional },
			};
			alterView("newSchedule", updatedServices);
			clearForm();
		} else {
			// Adiciona novo item
			const item = [newItem];
			alterView("newSchedule", [...item, ...servicesSelected]);
		}
	};

	useEffect(() => {
		if (editOtherService) {
			setValue("date", editOtherService.date);
			setValue("time", editOtherService.time);
			if (editOtherService.professional && editOtherService.professional.id) {
				setProfessional(editOtherService.professional);
			}
			setService(editOtherService.service);
			trigger().then((newIsValid) => {
				return newIsValid;
			});
		}
	}, [editOtherService, setValue, trigger]);

	return (
		<div style={{ width: "100%" }}>
			<div className="container-add-other-service">
				<div>
					<Text>Data e hora</Text>
					<div style={{ display: "flex", gap: "1rem" }}>
						<Inputs control={control} type="date" value="" name="date" style={{ width: "100%" }} />
						<Inputs control={control} type="time" value="" name="time" style={{ width: "100%" }} />
					</div>
				</div>
				<div>
					<Text>Profissional</Text>
					<Select
						variant="outlined"
						name="professionalTeste"
						defaultValue={professional?.id}
						value={professional?.id}
						onChange={(e) => {
							const selectedId = e.target.value;
							const selectedProfessional = business?.professionals?.find((prof) => prof.id === selectedId);
							setProfessional(selectedProfessional);
						}}
						fullWidth>
						{business?.professionals?.map((prof) => (
							<MenuItem key={prof.id} value={prof.id} style={{ display: "flex", flexDirection: "column" }}>
								{""}
								<p>{prof.name}</p>
							</MenuItem>
						))}
					</Select>
				</div>
				<div>
					<Text>Serviços</Text>
					<Button
						variant="outlined"
						className="selectBlock"
						endIcon={<ArrowForwardIos />}
						onClick={() => {
							if (professional) {
								setOpenServiceDialog(true);
							} else {
								handleFeedback("Atenção", "Você deve escolher o profissional primeiro!", "warning");
							}
						}}>
						{service ? (
							<div className="serviceText">
								<p>{service.service.name}</p>
								<p>{service.duration + "m"}</p>
								<p>{moneyMask(service?.price, business.currency)}</p>
							</div>
						) : (
							"Selecione um serviço"
						)}
					</Button>
				</div>

				<Dialog
					open={openServiceDialog}
					fullScreen
					PaperProps={{
						style: { backgroundColor: "transparent" },
					}}>
					<SelectServiceDialog
						onClose={() => setOpenServiceDialog(false)}
						select={setService}
						professionalCategoriesID={professional?.categories}
						professionalID={[professional?.id]}
					/>
				</Dialog>
			</div>
			<div style={{ width: "100%" }}>
				<GlobalButton
					title="Salvar"
					type="button"
					variant="outlined"
					handleClick={handleSubmit(onSubmit)}
					className="btn-color-primary"
					disabled={!isValid || !professional || !service}
					fullWidth={true}
				/>
			</div>
		</div>
	);
};

export default AddServiceScheduleDialog;
