import axios from "axios";
import { getAuth } from "firebase/auth";

const api = axios.create({
	baseURL: process.env.REACT_APP_URL_API,
	headers: {
		"content-type": "application/json",
	},
});

api.interceptors.request.use(
	async (config) => {
		const accessToken = localStorage.getItem("accessToken");
		let token = accessToken ? accessToken : null;

		const currentUser = getAuth().currentUser;

		if (currentUser) {
			token = currentUser.accessToken;
			config.headers.Authorization = `Bearer ${token}`;
		} else if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		} else {
			config.headers.Authorization = null;
		}

		config.headers["X-User-Agent"] = "WebBusiness";

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	(response) => response,
	async (error) => {
		const originalRequest = error.config;
		if (error.response?.status === 401 && !originalRequest._retry) {
			const currentUser = getAuth().currentUser;
			originalRequest._retry = true;
			if (currentUser) {
				await currentUser.getIdToken(true);
			}
			return api(originalRequest);
		}
		if (error.message?.includes("Network Error") || error.response?.status === 0) {
			return Promise.reject(error);
		}

		return Promise.reject(error);
	}
);

export default api;
