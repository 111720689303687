import React, { useState } from "react";
import { Alert, Box, Modal, Stack, Typography } from "@mui/material";
import { usePayment } from "context/payment.context";
import { MdChevronLeft } from "react-icons/md";

import { isMobile } from "utils/isMobile";
import { Button } from "components";

import clsx from "clsx";
import { BackButtonContainer } from "./styles-payment-method";

const PixQrCode = () => {
	const { setType, pixQrCode } = usePayment();

	const [copyPix, setCopyPix] = useState(false);
	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const [startTimer, setStartTimer] = useState(false);

	const copyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(pixQrCode);
			setOpen(true);
			setCopyPix(true);
			if (!startTimer) setStartTimer(true);
		} catch (err) {
			setCopyPix(false);
			setError("Falha ao copiar o código PIX. Tente novamente.");
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "80%", sm: "50%", md: "40%" },
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		borderRadius: "12px",
		p: 4,
		overflow: "auto",
	};

	return (
		<>
			<div className="payment-content">
				<div className="btn-back">
					<BackButtonContainer onClick={() => setType("PaymentMethod")}>
						<MdChevronLeft color="#585858" size={35} />
						<span>Voltar</span>
					</BackButtonContainer>
				</div>
				<div className={clsx("payment-box-inside", isMobile() && "mobile")}>
					<Typography
						variant="h5"
						align="center"
						color="textSecondary"
						gutterBottom
						sx={{ padding: "1rem 0 2rem", color: "#393762" }}>
						Pagamento via PIX
					</Typography>

					<Typography
						variant="subtitle2"
						align="center"
						color="textSecondary"
						component="p"
						sx={{ paddingBottom: "0.5rem", fontSize: "16px" }}>
						1. Copie o código abaixo
					</Typography>
					<Typography
						variant="subtitle2"
						align="center"
						color="textSecondary"
						component="p"
						sx={{ marginBottom: "1rem", fontSize: "16px" }}>
						2. Pague via PIX Copia e Cola em qualquer aplicativo habilitado
					</Typography>

					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "100%",
							padding: "16px",
							backgroundColor: "#f5f5f5",
							borderRadius: "8px",
							boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
							mb: 2,
							marginTop: "5%",
							cursor: "pointer",
							":hover": {
								backgroundColor: "#f0f0f0",
							},
						}}
						onClick={copyToClipboard}>
						<Typography
							variant="body1"
							sx={{
								wordBreak: "break-all",
								fontSize: "18px",
								color: "#393762",
								marginRight: "8px",
							}}>
							{pixQrCode}
						</Typography>
					</Box>

					{error && (
						<Stack sx={{ width: "100%", mt: 2 }} spacing={2}>
							<Alert variant="outlined" severity="error">
								{error}
							</Alert>
						</Stack>
					)}

					<Typography
						variant="subtitle2"
						sx={{
							fontSize: "15px",
							fontWeight: "bold",
							textAlign: "center",
							color: "grey",
							marginTop: "2rem",
						}}>
						Você tem até 10 minutos para fazer o pagamento.
					</Typography>

					<Button
						type="button"
						title={copyPix ? "PIX copiado" : "Copiar código PIX"}
						variant="contained"
						handleClick={() => copyToClipboard()}
						className={clsx("btn-color-primary btn-default", copyPix && "pix-copy")}
						fullWidth={true}
						style={{
							marginTop: "2rem",
						}}
					/>
					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="pix-modal-title"
						aria-describedby="pix-modal-description">
						<Box sx={style}>
							<Typography id="parent-modal-title" style={{ textAlign: "center", color: "#393762" }}>
								PIX
							</Typography>
							<p id="parent-modal-description" style={{ textAlign: "center" }}>
								Código PIX copiado com sucesso
							</p>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<button
									style={{
										backgroundColor: "#0074D9",
										color: "white",
										borderRadius: "10px",
										padding: "10px 20px",
										border: "none",
										cursor: "pointer",
									}}
									onClick={() => handleClose()}>
									Ok
								</button>
							</div>
						</Box>
					</Modal>
				</div>
			</div>
		</>
	);
};

export default PixQrCode;
