import React from "react";
import Chip from "@mui/material/Chip";

import "./style.scss";

const WithdrawStatus = (statusType) => {
	const statusStyles = (withdrawStatus) => {
		switch (withdrawStatus) {
			case "pendente":
				return { className: "pendingStatus", label: "Pendente" };
			case "processando":
				return { className: "processingStatus", label: "Processando" };
			case "disponivel":
				return { className: "availableStatus", label: "Disponível" };
			case "indefinido":
				return { className: "indefiniteStatus", label: "Indefinido" };
			default:
				return { className: "indefiniteStatus", label: "Indefinido" };
		}
	};

	const styles = statusStyles(statusType.statusType);

	return <Chip label={styles.label} className={styles.className} />;
};

export default WithdrawStatus;
