import { createContext, useContext, useState } from "react";

const ConfigContext = createContext();

let delayCloseFeedback = null;

export function ConfigProvider({ children }) {
	const [feedback, setFeedabck] = useState({
		open: false,
		tite: "",
		message: "",
		severity: "success",
	});
	const [loading, setLoading] = useState(false);
	const [typeAccess, setTypeAccess] = useState("schedule");

	// severity: "success" | "error" | "warning" | "info"
	const handleFeedback = (title, message, severity = "success", delayClose = 3000) => {
		clearTimeout(delayCloseFeedback);
		setFeedabck({
			open: true,
			title,
			message,
			severity,
		});
		delayCloseFeedback = setTimeout(() => {
			closeFeedback();
		}, delayClose);
	};

	const closeFeedback = () => {
		clearTimeout(delayCloseFeedback);
		setFeedabck({
			open: false,
			title: "",
			message: "",
			severity: "success",
		});
	};

	const handleLoading = (status) => {
		setLoading(status);
	};

	return (
		<ConfigContext.Provider
			value={{
				feedback,
				handleFeedback,
				closeFeedback,
				loading,
				handleLoading,
				setLoading,
				typeAccess,
				setTypeAccess,
			}}>
			{children}
		</ConfigContext.Provider>
	);
}

export function useConfig() {
	const context = useContext(ConfigContext);
	return context;
}
