import { Box } from "@material-ui/core";
import { ArrowForwardIos, Close } from "@material-ui/icons";
import { Alert, Button, Modal, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Button as GlobalButton, Status, Text } from "components";
import { useBusiness } from "context/business.context";
import { useConfig } from "context/config.context";
import { cancelSchedule, getBookingPaymentDetailsById, getBookingTransactionById } from "services/scheduleService";

import "styles/eventModal/index.scss";
import { getAppName } from "utils/validators";

const EventModal = ({ event, onClose, onEdit, reload }) => {
	const [paymentTransaction, setPaymentTransaction] = useState(null);
	const [bookingTransaction, setBookingTransaction] = useState(null);
	const [, setInternalNote] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [open, setOpen] = React.useState(false);

	const { handleFeedback, setLoading, handleLoading } = useConfig();
	const { setPageType } = useBusiness();

	const { business } = useSelector((store) => store.business);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const paymentVerification = (p) => {
		let paymentAntecipated;
		let paymentCheckout;
		const paymentTypesValidation = {
			cash: "Dinheiro",
			credit: "Cartão de crédito",
			creditprit: "Cartão de crédito",
			debit: "Cartão de débito",
			debitprit: "Cartão de débito",
			pixprit: "Pix",
			mbwayprit: "Mb-Way",
			undefined: `${getAppName(true)}`,
		};
		const descriptionValidation = [
			"PAGAMENTO DE ADIANTAMENTO DE RESERVA PERCENTUAL",

			"PAGAMENTO DE ADIANTAMENTO DE RESERVA FIXO",
			"PAGAMENTO DE ADIANTAMENTO DE RESERVA TOTAL",
		];
		if (p?.payments) {
			paymentAntecipated = p.payments?.find(
				(payment) =>
					(payment.payment_type === "credit" || payment.payment_type === "pix") &&
					payment.payments_description?.some((paymentDescription) =>
						descriptionValidation.includes(paymentDescription.description)
					)
			);

			paymentCheckout = p.payments?.find((v) =>
				v.payments_description.some(
					(paymentDescription) => !descriptionValidation.includes(paymentDescription.description)
				)
			);
		}

		const data = {
			discount: {
				value: p.total_discount,
				date: paymentAntecipated
					? moment(paymentAntecipated.payments_description[0].date_payment).format("DD/MM")
					: moment().format("DD/MM"),
			},
			paymentAntecipated: {
				exists: paymentAntecipated,
				date: paymentAntecipated
					? moment(paymentAntecipated.payments_description[0].date_payment).format("DD/MM")
					: moment().format("DD/MM"),
				value: paymentAntecipated ? paymentAntecipated.payments_description[0].payment_value : 0,
			},
			paymentCheckout: {
				exists: paymentCheckout,
				text: `${paymentCheckout ? paymentTypesValidation[paymentCheckout.payment_type] : ""} 'no checkout'`,
				date: paymentCheckout
					? moment(paymentCheckout.payments_description[0].date_payment).format("DD/MM")
					: moment().format("DD/MM"),
				value: paymentCheckout && paymentCheckout.payments_description[0].payment_value,
			},
			pritpayAvaliable: p.total_available > 0 && {
				text: (`${getAppName(true)} lançado em {{date}}`, { date: moment(p.total_available_date).format("DD/MM") }),
				value: p.total_available,
			},
			totalToPaid: p.balance_to_pay,
			totalServices: p.total_services,
			totalRefunded: p.total_refunded,

		};
		return data;
	};

	const formatedPrice = (value) => {
		const price = (value / 100).toFixed(2).replace(".", ",");
		return price;
	};

	const formatedData = (startTime, endTime) => {
		const formattedStart = moment(startTime).format("DD/MM/YYYY - HH:mm");
		const formattedEnd = moment(endTime).format("HH:mm");
		return `${formattedStart} até às ${formattedEnd}`;
	};

	const handleEditBooking = () => {
		const paymentsValidationToEdit = ["completed", "partial", "cancelled"];
		if (bookingTransaction && !paymentsValidationToEdit.includes(bookingTransaction?.paymentStatus)) {
			onEdit();
		} else {
			setErrorMessage("Não é possível fazer a edição da Reserva, verifique o status da reserva.");
		}
	};

	useEffect(() => {
		handleLoading(true);
		if (event) {
			const fetchPaymentAndTransaction = async () => {
				try {
					const [bookingTransactionResult, paymentTransactionResult] = await Promise.all([
						getBookingTransactionById(event?.bookingTransactionId),
						getBookingPaymentDetailsById(event?.bookingTransactionId),
					]);
					const data = paymentVerification(paymentTransactionResult.data);
					setPaymentTransaction({ data: paymentTransactionResult.data, filteredData: data });
					setBookingTransaction(bookingTransactionResult.data);
					setInternalNote(bookingTransactionResult.data?.internalNote || "");
				} catch (error) {
					console.log(error);
					setLoading(false);
					onClose();
				} finally {
					setLoading(false);
				}
			};
			fetchPaymentAndTransaction();
		} else {
			handleLoading(false);
			setLoading(false);
			onClose();
		}
	}, []);

	const handleCancel = async () => {
		try {
			setLoading(true);
			const data = {
				id: event?.bookingTransactionId,
				paymentStatus: "cancelled",
				cancellationReason: "business",
			};
			await cancelSchedule(event?.bookingTransactionId, data);
			handleFeedback("Sucesso", "Agendamento cancelado com sucesso!", "success");
			if (reload) {
				reload();
			} else {
				window.location.reload();
			}
		} catch (error) {
			console.error(error);
			handleFeedback("Erro", "Não foi possível realizar o cancelamento", "error");
		} finally {
			setLoading(false);
		}
	};


	if (bookingTransaction) {
		const booking = bookingTransaction.bookings[0];

		const SyledLogo = styled.div`
			background-color: #d9d9d9;
			background-size: cover;
			background-position: center;
			background-image: url("${(props) => props.backgroundImage || "none"}");
			width: 50px;
			height: 50px;
			border-radius: 100%;
			margin-right: 10px;
		`;

		return (
			<Modal open={true} onClose={onClose} aria-labelledby="Detalhes da Reserva" className="containerModal">
				<Box className="modal">
					<div className="headerModal">
						<div className="headerTitle">
							<Close onClick={onClose} style={{ fontSize: "1.5rem", cursor: "pointer", padding: "0.6rem" }} />
							<Text>Detalhes da Reserva</Text>
						</div>
						{onEdit && (
							<Text className="editButton" onClick={handleEditBooking}>
								Editar
							</Text>
						)}
					</div>
					{errorMessage && (
						<Stack sx={{ width: "100%" }}>
							<Alert severity="error" onClose={() => setErrorMessage("")}>
								{errorMessage}
							</Alert>
						</Stack>
					)}
					<section className="ownerSection" onClick={() => setPageType("businessProfile")}>
						<div className="ownerContainerInfo">
							<SyledLogo backgroundImage={business.thumbnailUrl} />
							<div className="ownerData">
								<Text>{business.name}</Text>
								<Text>{business.phone}</Text>
							</div>
						</div>
						<ArrowForwardIos style={{ fontSize: "1rem", cursor: "pointer", padding: "0.6rem" }} />
					</section>
					<div>
						<section className="bookingDetails">
							<div className="alignData">
								<p className="bold">Reserva</p>
								<Status statusType={booking?.confirmReservation ? "accept" : booking?.status} />
							</div>
							<div className="alignData">
								<Text>Data e horário:</Text>
								<Text>{formatedData(booking?.startTime, booking?.endTime)}</Text>
							</div>
							<div className="alignData">
								<Text>Serviço:</Text>
								<Text>{booking?.service?.name}</Text>
							</div>
							<div className="alignData">
								<Text>Profissional:</Text>
								<Text>{booking?.professional?.name}</Text>
							</div>
						</section>

						<section className="bookingDetails">
							<div className="alignData">
								<Text className="bold">Pagamento</Text>
								<Status statusType={bookingTransaction.paymentStatus} />
							</div>
							<div className="alignData">
								<Text>Desconto:</Text>
								<Text>R$ {formatedPrice(paymentTransaction?.filteredData?.discount?.value)}</Text>
							</div>
							<div className="alignData border">
								<Text>{`${getAppName(true)} antecipado:`}</Text>
								<Text>R$ {formatedPrice(paymentTransaction?.filteredData?.paymentAntecipated?.value)}</Text>
							</div>
							<div className="alignData" style={{ marginTop: "1rem" }}>
								<Text className="bold">Total a Pagar</Text>
								<Text className="bold">R$ {formatedPrice(paymentTransaction?.filteredData?.totalToPaid)} </Text>
							</div>
						</section>
						<div>
							<GlobalButton
								title="Cancelar"
								variant="contained"
								fullWidth={true}
								type="button"
								handleClick={handleClickOpen}
								className="btn-color-cancel btn-default"
							/>
						</div>
					</div>
					<Dialog open={open} onClose={handleClose}>
						<DialogContent>
							<DialogContentText>Deseja cancelar o agendamento?</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button type="button" onClick={handleCancel}>
								Confirmar
							</Button>
							<Button style={{ color: "red" }} type="button" onClick={handleClose} autoFocus>
								Cancelar
							</Button>
						</DialogActions>
					</Dialog>
				</Box>
			</Modal>
		);
	} else {
		return null;
	}
};

export default EventModal;
