import { MenuItem, Select } from "@material-ui/core";
import React from "react";

import BandeiraANG from "assets/icons/ANG.png";
import BandeiraBRA from "assets/icons/BRA.png";
import BandeiraESP from "assets/icons/ESP.png";
import BandeiraEUA from "assets/icons/EUA.png";
import BandeiraFRA from "assets/icons/FRA.png";
import BandeiraING from "assets/icons/ING.png";
import BandeiraPOR from "assets/icons/POR.png";

const SelectDDI = ({ value, handleChange, className, style }) => {
	return (
		<Select
			className={className}
			style={{
				paddingLeft: "16px",
				backgroundColor: "#D9D9D9",
				color: "#3E3E3E",
				borderRadius: "8px",
				height: "56px",
				...style,
			}}
			value={value}
			onChange={handleChange}>
			<MenuItem value={1}>
				<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraBRA} alt="BandeiraBRA" /> +55
			</MenuItem>
			<MenuItem value={2}>
				<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraPOR} alt="BandeiraPOR" /> +351
			</MenuItem>
			<MenuItem value={3}>
				<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraEUA} alt="BandeiraEUA" /> +1
			</MenuItem>
			<MenuItem value={4}>
				<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraING} alt="BandeiraING" /> +44
			</MenuItem>
			<MenuItem value={5}>
				<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraANG} alt="BandeiraANG" /> +244
			</MenuItem>
			<MenuItem value={6}>
				<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraFRA} alt="BandeiraFRA" /> +33
			</MenuItem>
			<MenuItem value={7}>
				<img style={{ width: "20px", paddingRight: "8px" }} src={BandeiraESP} alt="BandeiraESP" /> +34
			</MenuItem>
		</Select>
	);
};

export default SelectDDI;
