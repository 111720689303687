import React, { createContext, useContext, useEffect, useState } from "react";

export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
	const [hasInitiatedLogin, setHasInitiatedLogin] = useState(false);
	const [businessState, setBusinessState] = useState(null);

	const initiateLogin = () => {
		setHasInitiatedLogin(true);
	};

	const resetLogin = () => {
		setHasInitiatedLogin(false);
	};

	const startBusinessState = (business) => {
		window.localStorage.setItem("@prit:businessState", JSON.stringify(business));
		setBusinessState(business);
	};

	const getBusinessState = () => {
		const business = window.localStorage.getItem("@prit:businessState");
		if (business) {
			setBusinessState(JSON.parse(business));
		}
	};

	const clearBusinessState = () => {
		window.localStorage.removeItem("@prit:businessState");
		setBusinessState(null);
	};

	useEffect(() => {
		getBusinessState();
	}, []);

	return (
		<LoginContext.Provider
			value={{
				hasInitiatedLogin,
				initiateLogin,
				resetLogin,
				startBusinessState,
				getBusinessState,
				clearBusinessState,
				businessState,
			}}>
			{children}
		</LoginContext.Provider>
	);
};

export function useLogin() {
	const context = useContext(LoginContext);
	return context;
}
