import React, { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { useSelector } from "react-redux";
import { useMixpanel } from "react-mixpanel-browser";
import { addMinutes, format, formatISO, isBefore, parseISO, setHours, setMinutes } from "date-fns";
import { Box } from "@material-ui/core";
import { Button, Dialog, TextField } from "@material-ui/core";
import { Text } from "components";

import RepeatIcon from "@mui/icons-material/Repeat";
import AddIcon from "@mui/icons-material/Add";
import { ArrowForwardIos, Close, ArrowBackIos } from "@material-ui/icons";
import { Divider, MenuItem } from "@mui/material";
import SelectClientDialog from "components/dialog/dialog-select-client";

import { useServicesContext } from ".";
import { useConfig } from "context/config.context";
import vacanciesService from "services/vacanciesService";
import bookingTransactionService from "services/bookingTransactionService";

import { Button as BlobalButton, Container, GradientLoading } from "components";
import { moneyMask } from "utils/utils";
import { formatDate } from "utils/utils";
import SelectServiceDialog from "./dialog-select-service";
import RecurrentScheduleDialog from "../../recurrentSchedule/index.jsx";
import AddServiceScheduleDialog from "./addServiceScheduleDialog";
import ServiceChip from "../../../components/serviceChip";

import "./index.scss";

const NewScheduleDialog = (props) => {
	const initialDate = props.date;
	const mixpanel = useMixpanel();
	
	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	
	const now = new Date();
	now.setHours(now.getHours() + 1);

	const { handleFeedback } = useConfig();

	const { me } = useSelector((state) => state.me);
	const { business } = useSelector((store) => store.business);
	const auth = getAuth();

	const [isNewSchedule, setIsNewSchedule] = useState("newSchedule");
	const [openRecurrentDialog, setOpenRecurrentDialog] = useState(false);
	const [recurrencyReservationData, setRecurrencyReservationData] = useState("");
	const [client, setClient] = useState("");
	const [date, setDate] = useState(formatDate(initialDate.toISOString()));
	const [endDate, setEndDate] = useState(formatDate(tomorrow.toISOString()));
	const [time, setTime] = useState(new Date().toLocaleTimeString().slice(0, 5));

	const [openMessage, setOpenMessage] = useState(false);
	const [message, setMessage] = useState("Tudo certo");
	const [loading, setLoading] = useState(false);

	const [startTime, setStartTime] = useState(new Date().toLocaleTimeString().slice(0, 5));
	const [endTime, setEndTime] = useState(now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
	const [service, setService] = useState("");
	const { contextServices } = useServicesContext();
	const [professional, setProfessional] = useState("");
	const [blockReason, setBlockReason] = useState("");

	const [openClientDialog, setOpenClientDialog] = useState(false);
	const [openServiceDialog, setOpenServiceDialog] = useState(false);

	const [otherServices, setOtherServices] = useState([]);
	const [editOtherService, setEditOtherService] = useState(null);

	const alterView = (section, serviceAdd) => {
		setIsNewSchedule(section);
		setOtherServices(serviceAdd);
	};

	const formatStartEndTime = (date, time, duration = 0) => {
		let startTimeDate = parseISO(date);
		const [hour, minute] = time.split(":").map(Number);

		startTimeDate = setHours(startTimeDate, hour);
		startTimeDate = setMinutes(startTimeDate, minute);

		const endTimeDate = addMinutes(startTimeDate, duration);

		const startTimeFormatted = format(startTimeDate, "HH:mm");
		const endTimeFormatted = format(endTimeDate, "HH:mm");

		return {
			startTime: startTimeFormatted,
			endTime: endTimeFormatted,
		};
	};

	const clearForm = () => {
		setEditOtherService(null);
	};

	const listOtherServices = () => {
		if (!otherServices || otherServices.length === 0) {
			return null;
		}

		return otherServices.map((item, index) => (
			<ServiceChip
				serviceDuration={item.service?.duration ?? 0}
				serviceStartTime={formatStartEndTime(item.date, item.time, item.service?.duration).startTime}
				serviceEndTime={formatStartEndTime(item.date, item.time, item.service?.duration).endTime}
				serviceName={item.service?.service.name}
				servicePrice={item.service?.price}
				serviceProfessional={item.professional?.name ?? ""}
				edit={true}
				onClick={() => {
					handleOtherServiceClick(item, index);
				}}
			/>
		));
	};

	const handleOtherServiceClick = (item, index) => {
		if (professional) {
			setIsNewSchedule("addOtherService");
			setEditOtherService({ ...item, indice: index });
		} else {
			handleFeedback("Atenção", "Você deve escolher o profissional primeiro!", "warning");
		}
	};

	useEffect(() => {
		setEditOtherService(null);
		listOtherServices();
	}, []);

	const addOtherServicesBooking = () => {
		let otherServicesSelected = [];
		otherServices.map((item) => {
			let startTimeDate = parseISO(item.date);
			const [hour, minute] = item.time.split(":").map(Number);

			startTimeDate = setHours(startTimeDate, hour);
			startTimeDate = setMinutes(startTimeDate, minute);

			const endTimeDate = addMinutes(startTimeDate, item.service?.duration);

			const startTimeISO = formatISO(startTimeDate);
			const endTimeISO = formatISO(endTimeDate);
			const booking = {
				id: null,
				professional_id: item.professional?.id,
				service_id: item.service?.service?.id,
				start_time: startTimeISO,
				end_time: endTimeISO,
				professional: { ...item.professional },
				date: startTimeISO,
				time,
				service: item.service,
				feature: {},
				booking: {
					id: null,
					professional_id: professional.id,
					service_id: item.service?.service?.id,
					start_time: startTimeDate,
					end_time: endTimeDate,
				},
				price: item.service?.price,
				pricingTypeId: item.service?.pricingTypeId,
				businessId: business.id,
				clientId: client.id,
			};
			otherServicesSelected.push(booking);
		});

		return otherServicesSelected;
	};

	const handleError = (error, defaultMessage = "Ocorreu um erro inesperado. Por favor, tente novamente.") => {
		const knownErrors = {
			VACANCY_NOT_AVAILABLE: "O profissional não está disponível no horário selecionado.",
			SERVICE_DURATION_EXCEEDED: "A duração do serviço excede o horário disponível.",
			INVALID_CLIENT: "Cliente selecionado não é válido.",
		};

		const errorMessage = error?.response?.data?.message || error?.message || defaultMessage;

		if (knownErrors[errorMessage]) {
			return knownErrors[errorMessage];
		}

		console.error("Erro desconhecido:", error);
		return defaultMessage;
	};

	const validateReservationDate = () => {
		if (business && auth?.currentUser) {
			setLoading(true);
			let startTimeDate = parseISO(date);

			const [hour, minute] = time.split(":").map(Number);

			startTimeDate = setHours(startTimeDate, hour);
			startTimeDate = setMinutes(startTimeDate, minute);

			const startTimeISO = formatISO(startTimeDate);

			vacanciesService
				.checkVacancies(professional.id, startTimeISO)
				.then((res) => {
					if (res?.data?.message) {
						setMessage(res?.data?.message);
						setOpenMessage(true);
						setLoading(false);
					} else if (res?.status === 200) {
						saveReservation();
					}
				})
				.catch((err) => {
					handleFeedback("Erro", handleError(err), "error");
					setLoading(false);
				});
		}
	};

	const saveReservation = () => {
		setLoading(true);
		if (business && auth?.currentUser) {
			const servicesArray = Object.values(contextServices).find((service) => service.length > 0);
			const updatedService = servicesArray.find((s) => s.service.id === service.service.id);

			if (!updatedService) {
				setLoading(false);
				handleFeedback("Erro", "Serviço selecionado não encontrado.", "error");
				return;
			}

			let startTimeDate = parseISO(date);
			const [hour, minute] = time.split(":").map(Number);

			startTimeDate = setHours(startTimeDate, hour);
			startTimeDate = setMinutes(startTimeDate, minute);

			const endTimeDate = addMinutes(startTimeDate, updatedService.duration);

			const startTimeISO = formatISO(startTimeDate);
			const endTimeISO = formatISO(endTimeDate);
			const body = {
				bookings: [
					{
						id: null,
						professional_id: professional.id,
						service_id: updatedService.service.id,
						start_time: startTimeISO,
						end_time: endTimeISO,
						professional,
						date: startTimeISO,
						time,
						service: updatedService,
						feature: {},
						booking: {
							id: null,
							professional_id: professional.id,
							service_id: updatedService.id,
							start_time: startTimeDate,
							end_time: endTimeDate,
						},
						price: updatedService.price,
						pricingTypeId: updatedService.pricingTypeId,
						temp_id: updatedService.temp_id,
						businessId: business.id,
						clientId: client.id,
					},
				],
				internalNote: "",
				messageClient: "",
			};

			if (otherServices && otherServices.length > 0) {
				body.bookings.push(...addOtherServicesBooking());
			}

			bookingTransactionService
				.postBookingTransaction(body)
				.then((res) => {
					if (res.status === 200) {
						mixpanel.identify(me.phone ?? me.phoneUnverified);
						mixpanel.track("Adicionou nova reserva - Web Business");
						handleFeedback("Sucesso", "Reserva salva com sucesso.", "success");
						window.location.reload();
					}
				})
				.catch((err) => {
					handleFeedback("Erro", handleError(err, "Erro ao salvar a reserva. Tente novamente."), "error");
					setLoading(false);
				});
		}
	};

	const saveScheduleBlock = async () => {
		if (business && auth?.currentUser) {
			let startTimeDate = parseISO(date);
			let endTimeDate = parseISO(endDate);

			const [startHour, startMinute] = startTime.split(":").map(Number);
			const [endHour, endMinute] = endTime.split(":").map(Number);

			startTimeDate = setHours(startTimeDate, startHour);
			startTimeDate = setMinutes(startTimeDate, startMinute);

			endTimeDate = setHours(endTimeDate, endHour);
			endTimeDate = setMinutes(endTimeDate, endMinute);

			const startTimeISO = formatISO(startTimeDate);
			const endTimeISO = formatISO(endTimeDate);

			const body = {
				reason: blockReason,
				start_time: startTimeISO,
				end_time: endTimeISO,
				professional_id: professional.id,
			};

			if (isBefore(endTimeDate, startTimeDate)) {
				handleFeedback("Atenção", "A data de início deve anteceder a data final do bloqueio da reserva.", "warning");
			} else {
				try {
					setLoading(true);
					const response = await bookingTransactionService.postScheduleBreak(body);
					if (response.status === 200) {
						handleFeedback("Sucesso", "Bloqueio de horário salvo com sucesso.", "success");
						window.location.reload();
					}
				} catch (error) {
					setLoading(false);
					handleFeedback(
						"Erro",
						handleError(error, "Erro ao salvar o bloqueio de horário. Por favor, tente novamente.", "error"),
						"error"
					);
				}
			}
		}
	};

	const isSaveDisabled = (isNewSchedule === "newSchedule")
		? !client || !date || !time || !service || !professional
		: !date || !startTime || !endDate || !endTime || !professional;

	if (loading) {
		return (
			<div
				style={{
					position: "fixed",
					top: 0,
					left: 0,
					width: "100vw",
					height: "100vh",
					backgroundColor: "rgba(0, 0, 0, 0.8)",
					zIndex: 10000,
				}}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}>
					<GradientLoading />
				</Box>
			</div>
		);
	}

	const recurrencyReservation = () => {
		setLoading(true);

		if (business && auth?.currentUser) {
			const servicesArray = Object.values(contextServices).find((service) => service.length > 0);
			const updatedService = servicesArray.find((s) => s.id === service.id);

			if (!updatedService) {
				setLoading(false);
				handleFeedback("Erro", "Serviço selecionado não encontrado.", "error");
				return;
			}

			let startTimeDate = parseISO(date);

			const [hour, minute] = time.split(":").map(Number);
			startTimeDate = setHours(startTimeDate, hour);
			startTimeDate = setMinutes(startTimeDate, minute);
			const endTimeDate = addMinutes(startTimeDate, updatedService.duration);
			const startTimeISO = formatISO(startTimeDate);
			const endTimeISO = formatISO(endTimeDate);

			const body = {
				bookings: [
					{
						id: null,
						professional_id: professional.id,
						service_id: updatedService.service.id,
						start_time: startTimeISO,
						end_time: endTimeISO,
						professional,
						date: startTimeISO,
						time,
						service: updatedService,
						feature: {},

						booking: {
							id: null,
							professional_id: professional.id,
							service_id: updatedService.id,
							start_time: startTimeDate,
							end_time: endTimeDate,
						},

						price: updatedService.price,
						pricingTypeId: updatedService.pricingTypeId,
						temp_id: updatedService.temp_id,
						businessId: business.id,
						clientId: client.id,
					},
				],
				internalNote: "",
				messageClient: "",
			};
			setRecurrencyReservationData(body);
		}
		setLoading(false);
		setOpenRecurrentDialog(true);
	};

	const newSchedule = () => {
		return (
			<div className="newScheduleContainer">
				<div>
					<p>Cliente</p>
					<Button
						className="selectBlock"
						variant="outlined"
						endIcon={<ArrowForwardIos />}
						onClick={() => setOpenClientDialog(true)}>
						{client ? client.name : "Selecione um cliente"}
					</Button>
				</div>

				<div>
					<p>Data e Hora</p>
					<div className="inputTimeContainer">
						<TextField
							className="inputTime"
							value={date}
							variant="outlined"
							onChange={(e) => {
								setDate(e.target.value);
							}}
							type="date"
						/>
						<TextField
							className="inputTime"
							value={time}
							variant="outlined"
							onChange={(e) => {
								setTime(e.target.value);
							}}
							type="time"
						/>
					</div>
				</div>

				<div>
					<p>Profissional</p>
					<TextField
						select
						variant="outlined"
						value={professional}
						onChange={(e) => setProfessional(e.target.value)}
						fullWidth>
						{business?.professionals.map((prof) => (
							<MenuItem key={prof.id} value={prof} style={{ display: "flex", flexDirection: "column" }}>
								{" "}
								<p>{prof.name}</p>
							</MenuItem>
						))}
					</TextField>
				</div>

				<div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
					<div>
						<p>Serviço</p>
						<ServiceChip
							serviceDuration={service?.duration}
							serviceStartTime={formatStartEndTime(date, time, service?.duration).startTime}
							serviceEndTime={formatStartEndTime(date, time, service?.duration).endTime}
							serviceName={service?.service?.name}
							servicePrice={service?.price}
							serviceProfessional={otherServices && otherServices.length > 0 ? professional?.name : ""}
							edit={true}
							onClick={() => {
								if (professional) {
									setOpenServiceDialog(true);
								} else {
									handleFeedback("Atenção", "Você deve escolher o profissional primeiro!", "warning");
								}
							}}
						/>
					</div>
					{listOtherServices()}
					<div style={{display: "flex", flexDirection: "column", marginBotton: professional ? "0.1rem" : "0.5rem"}}>
						<button
							className="scheduleOptionsButton"
							style={{ cursor: isSaveDisabled ? "text" : "pointer" }}
							onClick={() => {
								recurrencyReservation();
							}}
							disabled={isSaveDisabled}>
							<div style={{cursor: "pointer"}}>
								<RepeatIcon style={{ color: isSaveDisabled ? "gray" : "#3689ea", fontSize: "18" }}/>
								<Text variant={'body1'} style={{ color: isSaveDisabled ? "gray" : "#3689ea"}}>Repetir agendamento</Text>
							</div>
						</button>
						<button
							className="scheduleOptionsButton"
							onClick={() => {
								setIsNewSchedule("addOtherService");
								clearForm();
							}}
							disabled={!service}
							>
							<div>
								<AddIcon style={{ color: !service ? "gray" : "#3689ea", fontSize: "18"}}/>
								<Text variant={'body1'} style={{ color: !service ? "gray" : "#3689ea" }}>
									Adicionar outro serviço
								</Text>
							</div>
						</button>
					</div>
				</div>
			</div>
		);
	};

	const newScheduleBlock = () => {
		return (
			<div className="newScheduleContainer">
				<div>
					<p>Início</p>
					<div className="inputTimeContainer">
						<TextField
							className="inputDateHours"
							value={date}
							variant="outlined"
							onChange={(e) => {
								setDate(e.target.value);
							}}
							type="date"
						/>

						<TextField
							className="inputDateHours"
							value={startTime}
							variant="outlined"
							onChange={(e) => {
								setStartTime(e.target.value);
							}}
							type="time"
						/>
					</div>
				</div>
				<div>
					<p>Término</p>
					<div className="inputTimeContainer">
						<TextField
							className="inputDateHours"
							value={endDate}
							variant="outlined"
							onChange={(e) => {
								setEndDate(e.target.value);
							}}
							type="date"
						/>
						<TextField
							className="inputDateHours"
							value={endTime}
							variant="outlined"
							onChange={(e) => {
								setEndTime(e.target.value);
							}}
							type="time"
						/>
					</div>
				</div>

				<div>
					<p>Profissional</p>
					<TextField
						select
						variant="outlined"
						value={professional}
						onChange={(e) => setProfessional(e.target.value)}
						fullWidth>
						{business?.professionals.map((prof) => (
							<MenuItem key={prof.id} value={prof} style={{ display: "flex", flexDirection: "column" }}>
								{prof.name}
							</MenuItem>
						))}
					</TextField>
				</div>
				<div>
					<p> Qual o motivo do bloqueio? (opcional)</p>
					<TextField
						variant="outlined"
						value={blockReason}
						onChange={(e) => setBlockReason(e.target.value)}
						fullWidth
					/>
				</div>
			</div>
		);
	};

	const selectSection = (section = "newSchedule") => {
		const select = {
			newSchedule: newSchedule(),
			newScheduleBlock: newScheduleBlock(),
			addOtherService: (
				<AddServiceScheduleDialog
					alterView={alterView}
					servicesSelected={otherServices}
					editOtherService={editOtherService}
					clearForm={clearForm}
				/>
			),
		};

		if (select[section]) {
			return select[section];
		}
	};

	return (
		<Container style={{ gap: "0.5rem" }} transparent>
			<div
				style={{
					display: "flex",
					justifyContent: `${isNewSchedule == "addOtherService" ? "space-between" : "end"}`,
					width: "100%",
				}}>
				{isNewSchedule == "addOtherService" && (
					<header className="headerContainer">
						<ArrowBackIos
							style={{ width: "30px", color: "#616161", cursor: "pointer" }}
							onClick={() => setIsNewSchedule("newSchedule")}
						/>
						<Text>Adicionar serviço</Text>
					</header>
				)}
				<Close style={{ color: "#3E3E3E", cursor: "pointer" }} onClick={props.onClose}></Close>
			</div>
			{isNewSchedule !== "addOtherService" && (
				<div style={{ display: "flex", justifyContent: "center", gap: "16px", width: "100%" }}>
					<BlobalButton
						title="Novo agendamento"
						type="button"
						variant="contained"
						handleClick={() => setIsNewSchedule("newSchedule")}
						className={`btn-default ${isNewSchedule === "newSchedule" ? "btn-color-primary" : "btn-color-secondary"}`}
						fullWidth={true}
					/>

					<BlobalButton
						title="Novo bloqueio"
						type="button"
						variant="contained"
						handleClick={() => setIsNewSchedule("newScheduleBlock")}
						className={`btn-default ${isNewSchedule === "newScheduleBlock" ? "btn-color-primary" : "btn-color-secondary"}`}
						fullWidth={true}
					/>
				</div>
			)}

			<div style={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%", justifyContent: "flex-end", alignContent: "flex-end" }}>
				{isNewSchedule && selectSection(isNewSchedule)}

				{isNewSchedule != "addOtherService" && (
					<BlobalButton
						type="button"
						title="Salvar"
						variant="outlined"
						handleClick={() => {
							isNewSchedule === ("newSchedule") ? validateReservationDate() : saveScheduleBlock();
						}}
						fullWidth={true}
						className={isSaveDisabled ? "btn-color-secondary btn-default" : "btn-color-primary btn-default"}
						disabled={isSaveDisabled}
					/>
				)}
			</div>

			<Dialog
				open={openClientDialog}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<SelectClientDialog
					onClose={() => setOpenClientDialog(false)}
					onCloseAll={() => {
						props.onClose();
					}}
					select={setClient}
				/>
			</Dialog>

			<Dialog
				open={openRecurrentDialog}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<RecurrentScheduleDialog
					onClose={() => setOpenRecurrentDialog(false)}
					serviceData={recurrencyReservationData}
				/>
			</Dialog>

			<Dialog
				open={openServiceDialog}
				fullScreen
				PaperProps={{
					style: { backgroundColor: "transparent" },
				}}>
				<SelectServiceDialog
					onClose={() => setOpenServiceDialog(false)}
					select={setService}
					professionalCategoriesID={professional?.categories}
					professionalID={[professional?.id]}
				/>
			</Dialog>

			<Dialog
				open={openMessage}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 400 },
				}}>
				<p style={{ color: "#3E3E3E", fontSize: "18px", padding: "16px", textAlign: "center" }}>{message}</p>
				<Divider />
				<div style={{ display: "flex", justifyContent: "space-around" }}>
					<Button style={{ padding: "16px" }} onClick={() => setOpenMessage(false)}>
						Cancelar
					</Button>
					<Divider flexItem orientation="vertical"></Divider>
					<Button
						style={{ padding: "16px" }}
						onClick={() => {
							setOpenMessage(false);
							saveReservation();
						}}>
						Continuar mesmo assim?
					</Button>
				</div>
			</Dialog>
		</Container>
	);
};

export default NewScheduleDialog;
