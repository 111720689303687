import * as Yup from "yup";

export const businessProfileSchema = Yup.object({
	price: Yup.string().required("Preço é obrigatório"),
});

export const initialBusinessForm = {
	hours: "1h",
	minutes: "0m",
	price_type: "5d306bd1-676b-4cd7-a79e-a6a22219cf46",
	price: "R$ 0,00",
};