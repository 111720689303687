import businessService from "../../services/businessService";
import scheduleService from "../../services/scheduleService";
import Constants from "../../helpers/Constants";

const getBusiness =
	(businessId, reload = false) =>
	(dispatch) => {
		scheduleService.getBookings(businessId).then(({ data }) => {
			dispatch({
				type: Constants.GET_BUSINESS,
				payload: { business: { ...data?.business, professionals: data?.professionals } },
			});
			reload && window.location.reload();
		});
	};

const updateBusinessAddress = (id, address) => async (dispatch) => {
	try {
		const { data } = await businessService.putBusinessAddress(id, address);
		dispatch({ type: Constants.UPDATE_BUSINESS_ADDRESS, payload: { business: data } });
	} catch (error) {
		console.error("Erro ao atualizar o endereço:", error);
		throw error;
	}
};

const updateBusiness = (data) => ({
	type: Constants.UPDATE_BUSINESS,
	payload: data,
});

const setBusinessInfo = (businessInfo) => ({
	type: Constants.SET_BUSINESS_INFO,
	payload: businessInfo,
});

const businessAction = {
	getBusiness,
	updateBusiness,
	updateBusinessAddress,
	setBusinessInfo,
};

export default businessAction;
