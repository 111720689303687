import React, { Fragment, useEffect, useRef } from "react";
import { Container } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import businessAction from "store/actions/businessAction";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddressSchema } from "helpers/schemas";
import { apiViaCep } from "services/apiViaCep";

import { useConfig } from "context/config.context";
import { ButtonCommom, Header, HeaderWelcome, Inputs } from "components";

import useStyles from "./styles-address";

const Address = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { handleFeedback, handleLoading, loading } = useConfig();

	const {
		control,
		watch,
		setValue,
		formState: { isValid },
	} = useForm({
		resolver: yupResolver(AddressSchema.addressSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: AddressSchema.initialAddressForm,
	});

	const businessInfor = useSelector((state) => state.business || {});

	const idPlan = location?.state.id;
	const isCardAndPlanUpdate = location?.state?.isCardAndPlanUpdate;
	const paymentMethod = location?.state?.method;

	useEffect(() => {
		if (watch("zipcode").length === 9) {
			handleZipCode();
		}
	}, [watch("zipcode")]);

	const handleZipCode = () => {
		handleLoading(true);
		try {
			const zipcode = watch("zipcode").replace("-", "");
			apiViaCep(zipcode).then((response) => {
				if (response) {
					setValue("address", response.logradouro);
					setValue("uf", response.uf);
					setValue("city", response.localidade);
					setValue("neighborhood", response.bairro, {
						shouldValidate: true,
						shouldDirty: true,
						shouldTouch: true,
					});
				}
			});
		} catch (error) {
			console.error("Erro ao buscar o CEP:", error);
		} finally {
			handleLoading(false);
		}
	};

	const handleSave = async () => {
		const address = {
			address_postal_code: watch("zipcode"),
			address_street: watch("address"),
			address_number: watch("number"),
			address_unit: watch("complement"),
			address_neighborhood: watch("neighborhood"),
			address_state: watch("uf"),
			address_city: watch("city"),
			address_country: "Brasil",
		};

		const getBusinessId = () => {
			if (businessInfor) {
				if (businessInfor.business && businessInfor.business.id) {
					return businessInfor.business.id;
				}
				return businessInfor.businessInfo.id;
			}
			return null;
		};

		if (getBusinessId()) {
			handleLoading(true);
			try {
				await dispatch(businessAction.updateBusinessAddress(getBusinessId(), address));
				handleLoading(false);
				if (paymentMethod === "pix") {
					navigate(`/subscription/method/pix`, { state: { id: idPlan, isCardAndPlanUpdate } });
				} else {
					navigate(`/subscription/method/credit`, { state: { id: idPlan, isCardAndPlanUpdate } });
				}
			} catch (error) {
				handleLoading(false);
				let errorMessage = "Ocorreu um erro desconhecido. Por favor, tente novamente.";

				if (error.response) {
					if (error.response.data && error.response.data.message) {
						errorMessage = error.response.data.message;
					} else {
						errorMessage = "Erro ao acessar a página. Verifique suas permissões.";
					}
				} else if (error.request) {
					errorMessage = "Não foi possível conectar ao servidor. Por favor, verifique sua conexão de internet.";
				} else {
					errorMessage = error.message;
				}
				handleFeedback("Erro", `Erro ao atualizar o endereço: ${errorMessage}`, "error");
			}
		}
	};

	const cepRef = useRef(null);
	const addressRef = useRef(null);
	const numberRef = useRef(null);
	const complementRef = useRef(null);
	const referencePointRef = useRef(null);
	const ufRef = useRef(null);
	const cityRef = useRef(null);
	const neighborhoodRef = useRef(null);

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				if (isValid) handleSave();
			}
		}
	};

	return (
		<Container maxWidth="sm" className={classes.container}>
			<HeaderWelcome />
			<Fragment>
				<div className={classes.formContainer}>
					<label htmlFor="cep" className={classes.label}>
						Cadastro de Endereço
					</label>
					<br />
					<form
						className={classes.form}
						style={{ display: "flex", flexDirection: "column", alignContent: "stretch", width: "100%", gap: "16px" }}>
						<Inputs
							control={control}
							name="zipcode"
							type="text"
							label="CEP"
							placeholder="Digite o CEP: 00000-000"
							inputRef={cepRef}
							handleKeyDown={(e) => handleKeyDown(e, addressRef)}
							inputProps={{
								maxLength: 9,
							}}
						/>
						<Inputs
							control={control}
							name="address"
							type="text"
							label="Logradouro"
							inputRef={addressRef}
							handleKeyDown={(e) => handleKeyDown(e, numberRef)}
						/>
						<Inputs
							control={control}
							name="number"
							type="text"
							label="Número"
							inputRef={numberRef}
							handleKeyDown={(e) => handleKeyDown(e, complementRef)}
						/>
						<Inputs
							control={control}
							name="complement"
							type="text"
							label="Complemento"
							helper='Caso não tenha, escreva "sem complemento"'
							inputRef={complementRef}
							handleKeyDown={(e) => handleKeyDown(e, referencePointRef)}
						/>
						<Inputs
							control={control}
							name="referencePoint"
							type="text"
							label="Ponto de Referência"
							placeholder="Digite o ponto de referência"
							inputRef={referencePointRef}
							handleKeyDown={(e) => handleKeyDown(e, ufRef)}
						/>
						<Inputs
							control={control}
							name="uf"
							type="text"
							label="UF"
							inputRef={ufRef}
							handleKeyDown={(e) => handleKeyDown(e, cityRef)}
						/>
						<Inputs
							control={control}
							name="city"
							type="text"
							label="Cidade"
							inputRef={cityRef}
							handleKeyDown={(e) => handleKeyDown(e, neighborhoodRef)}
						/>
						<Inputs
							control={control}
							name="neighborhood"
							type="text"
							label="Bairro"
							inputRef={neighborhoodRef}
							handleKeyDown={(e) => handleKeyDown(e, null)}
						/>
						<ButtonCommom
							text="Salvar"
							style={{
								width: "100%",
								fontSize: window.innerWidth > 600 ? "1em" : "2em",
								height: "45px",
							}}
							onClick={handleSave}
							disable={!isValid || loading}
						/>
					</form>
				</div>
			</Fragment>
		</Container>
	);
};

export default Address;
