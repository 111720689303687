import React from "react";
import { Button, Dialog, Divider, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	message: {
		textAlign: "center",
		color: "gray",
	},
	button: {
		padding: "15px",
		display: "flex",
		justifyContent: "space-around",
	},
}));

const OkModal = (props) => {
	const classes = useStyles();
	const { open, setOpen, onConfirm, message } = props;

	return (
		<Dialog
			open={open}
			PaperProps={{
				style: { borderRadius: 20, maxWidth: 400 },
			}}>
			<Typography style={{ margin: "30px" }} className={classes.message}>
				<strong>{message}</strong>
			</Typography>
			<Divider></Divider>
			<Button
				className={classes.button}
				onClick={() => {
					setOpen(false);
					if (onConfirm) {
						onConfirm();
					}
				}}>
				Ok
			</Button>
		</Dialog>
	);
};
export default OkModal;
