import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";

import { usePayment } from "context/payment.context";
import { useConfig } from "context/config.context";

import paymentService from "services/paymentService";

import { Button, Text } from "components";

import { isMobile } from "utils/isMobile";

const ActualPlan = () => {
	const {
		setType,
		setIsCardAndPlanUpdate,
		subscriptionCurrent,
		setSubscriptionCurrent,
		expirationDate,
		setExpirationDate,
	} = usePayment();

	const formattedDate = format(expirationDate, "dd MMMM yyyy", { locale: ptBR });

	const navigate = useNavigate();
	const location = useLocation();
	const { handleLoading, loading } = useConfig();

	const card = location?.state?.card;
	const cancel = location?.state?.cancel;

	const handleGetSubscription = async () => {
		handleLoading(true);
		try {
			const businessPayment = await paymentService.getPaySubscriptionCredit(businessInfor.business.id);
			setSubscriptionCurrent(businessPayment.data);
			setExpirationDate(businessPayment.data.expiration);
		} catch (error) {
			const errorMessage = error.response?.data?.message || error.message;
			console.log("errorMessage", errorMessage);
		} finally {
			handleLoading(false);
		}
	};
	const businessInfor = useSelector((state) => state.business);
	//let expirationDate = subscriptionCurrent ? new Date(subscriptionCurrent.expiration) : new Date();

	const style = {
		p: 0,
		width: "100%",
		borderRadius: 2,
		border: "1px solid",
		borderColor: "divider",
		backgroundColor: "background.paper",
		margin: "30% 0px 5% 0px",
	};

	const handleSubscriptionChange = () => {
		navigate("/subscription/onboarding", { state: { isCardAndPlanUpdate: true, view: true } }); // setType
	};

	const cardCreditChange = () => {
		navigate("/subscription/method/credit", { state: { cardUpdate: true } }); // setType
	};

	const handleDetailsCard = () => {
		navigate("/subscription/details"); // setType
	};

	const handleCancel = () => {
		navigate("/subscription/cancelar"); //setType
	};

	useEffect(() => {
		if (businessInfor && businessInfor.business) handleGetSubscription();
	}, [businessInfor]);

	return (
		!loading && (
			<>
				<div className="payment-content" style={{ paddingTop: "4rem" }}>
					<Text variant={"title"} style={{ color: "#393762" }}>
						Seu plano atual: {subscriptionCurrent?.current_plan.toUpperCase()}
					</Text>
					{!card?.is_recurrence ? (
						<>
							<Text style={{ color: "#585858", padding: "0% 0px 10% 0px" }}>
								{cancel ? `Seu plano estará ativo até o dia ${formattedDate}` : `Seu plano expira em  ${formattedDate}`}
							</Text>
							<div style={{ width: isMobile() ? "100%" : "50%" }}>
								<Button
									type="button"
									variant="contained"
									title={cancel ? "Contratar Plano" : "Alterar Plano"}
									fullWidth={true}
									className="btn-color-primary btn-default"
									handleClick={() => {
										setIsCardAndPlanUpdate(true);
										setType("PritPlan");
									}}
								/>
							</div>
						</>
					) : (
						<>
							<List sx={style} aria-label="mailbox folders">
								<ListItem>
									<Text style={{ color: "#585858" }}>
										Plano atual:{" "}
										<span style={{ color: "#393662", cursor: "pointer" }}> {subscriptionCurrent.current_plan} </span>
										<Text>
											Sua próxima data de cobrança é em{" "}
											<span style={{ color: "#393662", cursor: "pointer" }}> {formattedDate} </span>{" "}
										</Text>
									</Text>
								</ListItem>
								<Divider component="li" />
								<ListItem>
									<Text style={{ color: "#585858" }}>
										Cartão de crédito terminado em •••• {subscriptionCurrent.card_last_digits}
										<Text onClick={cardCreditChange}>
											<span
												style={{ color: "blue", cursor: "pointer", fontWeight: "bold", textDecoration: "underline" }}>
												{" "}
												Editar{" "}
											</span>{" "}
										</Text>
									</Text>
								</ListItem>
								<Divider component="li" />
								<ListItem>
									<Text onClick={handleDetailsCard} style={{ color: "#585858", textDecoration: "underline" }}>
										{" "}
										Detalhes da cobrança{" "}
									</Text>
								</ListItem>
								<Divider component="li" />
								<ListItem>
									<Text onClick={handleSubscriptionChange} style={{ color: "#585858", textDecoration: "underline" }}>
										{" "}
										Alterar plano de assinatura{" "}
									</Text>
								</ListItem>
								<Divider component="li" />
								<ListItem>
									<Text onClick={handleCancel} style={{ color: "#585858", textDecoration: "underline" }}>
										{" "}
										Cancelar assinatura{" "}
									</Text>
								</ListItem>
							</List>
						</>
					)}
				</div>
			</>
		)
	);
};
export default ActualPlan;
