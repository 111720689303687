import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Typography } from "@material-ui/core";
import { Divider } from "@mui/material";

import { HeaderWelcome } from "components";

import paymentService from "services/paymentService";

const DetailsPayment = () => {
	const businessInfor = useSelector((state) => state.business.business);
	const [details, setDetails] = useState("");

	const business = businessInfor;

	useEffect(() => {
		const data = {
			recipient_id: business?.id,
			count: 10,
			page: 1,
		};
		paymentService
			.postRecurrenceTransactions(data)
			.then((response) => {
				setDetails(response.data);
			})
			.catch((error) => {
				let errorMessage = error.message;

				if (error.response && error.response.data) {
					errorMessage = error.response.data.message || error.response.data;
					console.error(errorMessage);
				}
			});
	}, []); // Array vazio como segundo argumento

	const formattedDate = (transactions) => {
		const date = new Date(transactions);
		const formattedDate = date.toLocaleDateString("pt-BR");
		return formattedDate;
	};

	return (
		<Container
			maxWidth="sm"
			style={{
				background: "#fff",
				borderRadius: 3,
				border: 0,
				color: "white",
				minWidth: "100vw",
				minHeight: "100vh",
				padding: "0 30px",
				boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
			}}>
			<HeaderWelcome />

			<Typography
				variant="subtitle2"
				style={{ color: "#585858", fontSize: "18px", padding: "15% 0px 0% 0px", fontWeight: "bold" }}>
				Detalhes da Cobrança
			</Typography>
			<Divider component="li" />
			{details?.transactions?.map((transactions, index) => {
				return (
					<>
						<Typography
							key={index}
							variant="subtitle2"
							style={{ color: "rgb(54, 136, 235)", fontSize: "18px", padding: "1% 0px 0% 0px", fontWeight: "bold" }}>
							{formattedDate(transactions.date_created)}
						</Typography>
						<div
							style={{ display: "flex", justifyContent: "space-between", fontSize: "18px", padding: "1% 0px 0% 0px" }}>
							<Typography key={index} variant="subtitle2" style={{ color: "grey", fontSize: "18px" }}>
								Plano: {details.current_plan}
							</Typography>
							<Typography key={index} variant="subtitle2" style={{ color: "grey", fontSize: "18px" }}>
								R$ {(transactions.amount / 100).toFixed(2)}
							</Typography>
						</div>

						<Typography variant="subtitle2" style={{ color: "#585858", fontSize: "18px" }}>
							{" "}
							•••• {transactions.card_last_digits}{" "}
						</Typography>

						<Divider component="li" />
					</>
				);
			})}
		</Container>
	);
};

export default DetailsPayment;
