import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Box, Modal, Stack } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { MdChevronLeft } from "react-icons/md";
import { useSelector } from "react-redux";

import { Button, ButtonCommom, Inputs, Text } from "components";
import { useConfig } from "context/config.context";
import { usePayment } from "context/payment.context";
import { PaymentSchema } from "helpers/schemas";
import paymentService from "services/paymentService";
import { isMobile } from "utils/isMobile";
import { getAppName } from "utils/validators";

import { BackButtonContainer } from "./styles-payment-method";

const Pix = () => {
	const { setType, data, pixQrCode, setPixQrCode } = usePayment();
	const idPlan = data?.id;

	const [error, setError] = useState("");
	const [open, setOpen] = useState(false);
	const { loading, handleLoading } = useConfig(false);

	const user = useSelector((state) => state.me.me);
	const businessInfor = useSelector((state) => state.business.business);

	const me = user;
	const business = businessInfor;

	const {
		control,
		watch,
		setValue,
		formState: { isValid },
	} = useForm({
		resolver: yupResolver(PaymentSchema.pixSchema),
		reValidateMode: "onChange",
		mode: "onChange",
		defaultValues: PaymentSchema.initalPixForm,
	});

	const documentRef = useRef(null);

	const handlePayment = () => {
		handleLoading(true);
		const documentType = watch("documentType") ? "cnpj" : "cpf";
		const body = {
			payment_subscription_type: "pix",
			recipient_id: business?.id,
			doc: watch("document").replace(/\D/g, ""),
			doc_type: documentType,
			email: me?.email || "appprit@gmail.com",
			name: me?.name || getAppName(),
			phone: me?.phone || "+5516982099585",
			neighborhood: business?.address.neighborhood || "Jardim Botânico",
			city: business?.address.city || "Ribeirão Preto",
			state: business?.address.state || "SP",
			street: business?.address.street || "Av. Professor João Fiúsa",
			street_number: business?.address.number || 1901,
			zip_code: business?.address.postalCode || "14024250",
			plan_id: idPlan,
		};

		paymentService
			.postPaySubscription(body)
			.then((response) => {
				setPixQrCode(response.data.pixQrCode);
				setType("PixQrCode");
				handleLoading(false);
			})
			.catch((error) => {
				let errorMessage = error.message;
				if (error.response && error.response.data) {
					errorMessage = error.response.data.message || error.response.data;
				}
				setError(errorMessage);
				handleLoading(false);
			});
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleKeyDown = (e, nextFieldRef) => {
		if (e.key === "Enter") {
			e.preventDefault();
			if (nextFieldRef && nextFieldRef.current) {
				nextFieldRef.current.focus();
			} else {
				handlePayment();
			}
		}
	};

	useEffect(() => {
		if (documentRef.current) {
			setValue("document", "");
			documentRef.current.focus();
		}
	}, [watch("documentType")]);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "80%", sm: "50%", md: "40%" },
		bgcolor: "background.paper",
		border: "none",
		boxShadow: 24,
		borderRadius: "12px",
		p: 4,
		overflow: "auto",
	};

	return (
		<>
			<div className="payment-content">
				<div className="btn-back">
					<BackButtonContainer onClick={() => setType("PaymentMethod")}>
						<MdChevronLeft color="#585858" size={35} />
						<span>Voltar</span>
					</BackButtonContainer>
				</div>
				<div className={clsx("payment-box-inside", isMobile() && "mobile")}>
					<Text variant="mid" align="center" style={{ padding: "5% 0 2% 0", color: "#393762" }}>
						Informe os dados do seu PIX
					</Text>
					<Text align="center" style={{ marginBottom: "2%" }}>
						Nesta opção, o pagamento da mensalidade será feito de forma recorrente via PIX.
					</Text>

					<Box textAlign="center" sx={{ mb: 3 }}></Box>

					<form
						noValidate
						autoComplete="off"
						style={{
							marginTop: "2rem",
							marginBottom: "2rem",
							display: "flex",
							flexDirection: "column",
							alignContent: "stretch",
							width: "100%",
							gap: "16px",
						}}>
						<Inputs
							control={control}
							name="document"
							mask={watch("documentType") ? "cnpj" : "cpf"}
							type="text"
							label={`Insira o seu ${watch("documentType") ? "CNPJ" : "CPF"}`}
							placeholder={watch("documentType") ? "00.000.000/0000-00" : "000.000.000-00"}
							inputRef={documentRef}
							handleKeyDown={(e) => handleKeyDown(e, null)}
							inputProps={{
								maxLength: watch("documentType") ? 18 : 14,
								pattern: "[0-9]*",
								inputMode: "numeric",
							}}
						/>
						<Inputs control={control} name="documentType" label="CNPJ" type="switch" className="input-form-checkbox" />

						<Box textAlign="bottom">
							<Button
								type="button"
								disabled={!isValid || loading}
								title={watch("documentType") ? "SEGUIR COM CNPJ" : "SEGUIR COM CPF"}
								variant="contained"
								handleClick={() => handlePayment()}
								className="btn-color-primary btn-default"
								fullWidth={true}
							/>
						</Box>

						{error && (
							<Stack sx={{ width: "100%" }} spacing={2}>
								<Alert variant="outlined" severity="error">
									Ocorreu um erro: {error}
								</Alert>
							</Stack>
						)}
					</form>

					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="pix-modal-title"
						aria-describedby="pix-modal-description">
						<Box sx={style}>
							<Text id="pix-modal-title" style={{ textAlign: "center", color: "#393762", mb: 2 }}>
								Pagamento PIX
							</Text>
							<Box id="pix-modal-description" sx={{ textAlign: "center", mb: 2 }}>
								<Text>{pixQrCode}</Text>
							</Box>
							<Box sx={{ display: "flex", justifyContent: "center" }}>
								<ButtonCommom
									text="Copiar QR Code"
									onClick={() => navigator.clipboard.writeText(pixQrCode)}
									style={{
										backgroundColor: "#0074D9",
										color: "white",
										borderRadius: "10px",
										padding: "10px 20px",
										border: "none",
										cursor: "pointer",
										width: "100%",
										fontFamily: "Poppins",
									}}
								/>
							</Box>
						</Box>
					</Modal>
				</div>
			</div>
		</>
	);
};

export default Pix;
