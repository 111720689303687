import React, { useEffect } from "react";
import { useNavigate } from "react-router";

import { ReactComponent as Logo } from "assets/images/Boneco31.svg";
import welcomeZDoctors from "assets/images/welcome-zdoctors.png";
import { ButtonCore, Container, Text } from "components";
import { useConfig } from "context/config.context";
import { getAppName } from "utils/validators";

import "./index.scss";

const Welcome = () => {
	const navigate = useNavigate();

	const { setTypeAccess } = useConfig();

	useEffect(() => {
		setTypeAccess("schedule");
		localStorage.clear();
	}, []);

	return (
		<Container style={{ flex: "wrap", overflow: "auto" }}>
			<div className="circleBox">
				<div className="imgContainer">
					{getAppName() === "Prit" ? <Logo /> : <img src={welcomeZDoctors} width={"70%"} alt="Z Doctors" />}
				</div>
				<div className="circleBgForMobile"></div>
				<div className="contentContainer">
					<Text variant="title">Bem-vindo ao {getAppName()}!</Text>
					<Text align="center">O app que vai revolucionar a forma que você faz e recebe seus agendamentos.</Text>
				</div>
			</div>

			<div className="buttonContainer">
				<ButtonCore
					text="Criar conta"
					onClick={() =>
						navigate("/login", {
							state: {
								newBusiness: true,
							},
						})
					}
				/>
				<ButtonCore
					invertColors
					text="Já sou cadastrado!"
					onClick={() =>
						navigate("/login", {
							state: {
								newBusiness: false,
							},
						})
					}
				/>
			</div>
		</Container>
	);
};

export default Welcome;
