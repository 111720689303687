import axios from "axios";

export const apiViaCep = (zipcode) =>
	axios
		.get(`https://viacep.com.br/ws/${zipcode}/json/`)
		.then((response) => {
			const data = response.data;
			return data;
		})
		.catch((error) => {
			console.error("Erro ao buscar o CEP:", error);
			return null;
		});
