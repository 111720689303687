import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { SpinnerLoading, Text } from "components";

import useStyles from "../styles";

const HoursProfessionals = ({ hoursSelected = () => {}, professionalSelected, reset }) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);

	const daysOfWeek = [
		{ name: "sunday", label: "Domingo", weekday: 0 },
		{ name: "monday", label: "Segunda", weekday: 1 },
		{ name: "tuesday", label: "Terça", weekday: 2 },
		{ name: "wednesday", label: "Quarta", weekday: 3 },
		{ name: "thursday", label: "Quinta", weekday: 4 },
		{ name: "friday", label: "Sexta", weekday: 5 },
		{ name: "saturday", label: "Sábado", weekday: 6 },
	];

	const businessHours = useSelector((state) => state.business.business.businessHours);

	const [daySchedules, setDaySchedules] = useState(
		daysOfWeek.map((day) => ({ ...day, inicio: "08:00", fim: "18:00", ativo: false, intervalos: [] }))
	);

	const handleAddInterval = (dayName) => {
		setDaySchedules((prevDaySchedules) =>
			prevDaySchedules.map((day) => {
				if (day.name === dayName && day.ativo) {
					return {
						...day,
						intervalos: [...day.intervalos, { inicio: "", fim: "" }],
					};
				}
				return day;
			})
		);
	};

	const handleIntervalTimeChange = (event, dayName, intervalIndex, timeType) => {
		setDaySchedules((prevDaySchedules) =>
			prevDaySchedules.map((day) => {
				if (day.name === dayName) {
					const updatedIntervalos = day.intervalos.map((intervalo, index) => {
						if (index === intervalIndex) {
							return { ...intervalo, [timeType]: event.target.value };
						}
						return intervalo;
					});
					return { ...day, intervalos: updatedIntervalos };
				}
				return day;
			})
		);
	};

	const handleRemoveInterval = (dayName, intervalIndex) => {
		setDaySchedules((prevDaySchedules) =>
			prevDaySchedules.map((day) => {
				if (day.name === dayName) {
					const updatedIntervalos = day.intervalos.filter((_, index) => index !== intervalIndex);
					return { ...day, intervalos: updatedIntervalos };
				}
				return day;
			})
		);
		handleSubmit();
	};

	const handleCheckboxChange = (event, dayName) => {
		setDaySchedules((prevSchedules) =>
			prevSchedules.map((day) => (day.name === dayName ? { ...day, ativo: event.target.checked } : day))
		);
	};

	const handleTimeChange = (event, dayName, timeType) => {
		setDaySchedules((prevSchedules) =>
			prevSchedules.map((day) => (day.name === dayName ? { ...day, [timeType]: event.target.value } : day))
		);
	};

	const handleSubmit = async () => {
		const formattedSchedules = daySchedules
			.filter((day) => day.ativo)
			.flatMap((day) => {
				const horarios = [
					{
						openTime: day.inicio,
						closeTime: day.fim,
						weekday: day.weekday,
					},
					...day.intervalos.map((intervalo) => ({
						openTime: intervalo.inicio,
						closeTime: intervalo.fim,
						weekday: day.weekday,
					})),
				];

				return horarios;
			});

		hoursSelected(formattedSchedules);
	};

	function formatToTime(isoString) {
		const date = new Date(isoString);
		const hours = date.getUTCHours().toString().padStart(2, "0");
		const minutes = date.getUTCMinutes().toString().padStart(2, "0");
		return `${hours}:${minutes}`;
	}

	const startHours = () => {
		const initialSchedules = daysOfWeek.map((day) => {
			const dayBusinessHours = businessHours.filter((h) => h.weekday === day.weekday);
			if (dayBusinessHours.length > 0) {
				const mainHours = dayBusinessHours[0];
				const intervals = dayBusinessHours.slice(1).map((h) => ({
					inicio: formatToTime(h.openTime),
					fim: formatToTime(h.closeTime),
				}));
				return {
					...day,
					inicio: formatToTime(mainHours.openTime),
					fim: formatToTime(mainHours.closeTime),
					ativo: true,
					intervalos: intervals,
				};
			} else {
				return {
					...day,
					inicio: "",
					fim: "",
					ativo: false,
					intervalos: [],
				};
			}
		});
		setDaySchedules(initialSchedules);
	};

	useEffect(() => {
		setLoading(true);
		if (professionalSelected && professionalSelected.businessHours) {
			const initialSchedules = daysOfWeek.map((day) => {
				const existingHours = professionalSelected.businessHours.filter((h) => h.weekday === day.weekday);
				if (existingHours.length > 0) {
					const formattedIntervals = existingHours.slice(1).map((hour) => ({
						inicio: formatToTime(hour.openTime),
						fim: formatToTime(hour.closeTime),
					}));
					return {
						...day,
						inicio: formatToTime(existingHours[0].openTime),
						fim: formatToTime(existingHours[0].closeTime),
						ativo: true,
						intervalos: formattedIntervals,
					};
				}
				return { ...day, inicio: "", fim: "", ativo: false, intervalos: [] };
			});
			setDaySchedules(initialSchedules);
		} else if (reset) {
			startHours();
		}
		setLoading(false);
	}, [professionalSelected, reset]);

	useEffect(() => {
		handleSubmit();
	}, [daySchedules]);

	useEffect(() => {
		startHours();
	}, [businessHours]);

	return (
		<>
			<SpinnerLoading loading={loading}></SpinnerLoading>
			<div className={classes.hoursProfessionalsContainer}>
				{/* header */}
				<div className={classes.hoursProfessionalHeader} style={{ paddingLeft: "2rem" }}>
					<Text className={classes.labelText}>Horário de funcionamento</Text>
				</div>
				{/* main */}
				<form onSubmit={handleSubmit} className={classes.hoursProfessionalsContainer}>
					<div className={classes.mainHoursContainer}>
						{daySchedules.map((day) => (
							<div key={day.name}>
								<div className={classes.hoursContainer}>
									<input
										checked={day.ativo}
										type="checkbox"
										onChange={(event) => handleCheckboxChange(event, day.name)}
										className={classes.inpuFormCheckboxOpeningHours}
									/>
									<label className={classes.hoursLabelText}>{day.label}</label>
									<input
										type="time"
										value={day.inicio}
										onChange={(event) => handleTimeChange(event, day.name, "inicio")}
										disabled={!day.ativo}
										className={classes.inputHours}
									/>
									<input
										type="time"
										value={day.fim}
										onChange={(event) => handleTimeChange(event, day.name, "fim")}
										disabled={!day.ativo}
										className={classes.inputHours}
									/>
									<button
										disabled={!day.ativo}
										className={classes.addPlus}
										onClick={() => handleAddInterval(day.name)}
										type="button">
										+
									</button>
								</div>
								{(day.intervalos ?? []).map((intervalo, index) => (
									<div key={index} className={classes.intervalContainer}>
										<input
											type="time"
											value={intervalo.inicio}
											onChange={(e) => handleIntervalTimeChange(e, day.name, index, "inicio")}
											className={classes.inputHours}
										/>
										<input
											type="time"
											value={intervalo.fim}
											onChange={(e) => handleIntervalTimeChange(e, day.name, index, "fim")}
											className={classes.inputHours}
										/>
										<button
											onClick={() => handleRemoveInterval(day.name, index)}
											className={classes.addPlus}
											type="button">
											-
										</button>
									</div>
								))}
							</div>
						))}
					</div>
				</form>
			</div>
		</>
	);
};

export default HoursProfessionals;
