import React, { useEffect, useState } from "react";
import { useMixpanel } from "react-mixpanel-browser";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { AppBar, Chip, Container, Text } from "components";
import { useConfig } from "context/config.context";
import { useLogin } from "context/login.context";
import sectorService from "services/sectorService";
import { trimTelephoneNumber } from "utils";
import { getAppName } from "utils/validators";

const SelectSector = () => {
	const [validateApp, setValidateApp] = useState(false);

	const navigate = useNavigate();
	const { setLoading } = useConfig();
	const mixpanel = useMixpanel();

	const { me } = useSelector((state) => state.me);
	const { businessState } = useLogin();

	const [sectors, setSectors] = useState([]);

	useEffect(() => {
		setLoading(true);

		const request = setTimeout(() => {
			sectorService.getSectors().then((res) => {
				setSectors(res.data.sort((a, b) => a.name.localeCompare(b.name)));
				setLoading(false);
			});
		}, 1000);

		return () => clearInterval(request);
	}, []);

	const getPhoneNumber = () => {
		if (me) {
			return me.phone ?? me.phoneUnverified;
		} else {
			return "+" + businessState?.ddi + trimTelephoneNumber(businessState?.telephoneNumber);
		}
	};

	useEffect(() => {
		if (getAppName() === "Z Doctors" && businessState) {
			setValidateApp(true);
			mixpanel.identify(getPhoneNumber());
			mixpanel.track("onb. Avançar Setor - Web Business");
			navigate("/selecionar-categorias", {
				state: {
					phone: businessState.telephoneNumber,
					fullName: businessState.fullName,
					email: businessState.email,
					businessName: businessState.businessName,
					sectorId: process.env.REACT_APP_SECTOR_ID,
				},
			});
		} else {
			setValidateApp(true);
		}
	}, [businessState]);

	return (
		validateApp && (
			<Container style={{ gap: "16px" }}>
				<div style={{ display: "flex", alignContent: "center", width: "100%", marginBottom: "1rem" }}>
					<AppBar progress={75} />
					<Chip help={true} />
				</div>

				<Text style={{ color: "#3E3E3E" }} variant={"mid"}>
					Qual sua área de atuação?
				</Text>

				<Text style={{ color: "#9C9C9C", marginBottom: "1rem" }}>
					Informe a sua área de atuação para que possamos prosseguir o cadastro com as opções mais adequadas a sua
					realidade.
				</Text>

				<div
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(2, 1fr)",
						gap: "16px",
						width: "100%",
						paddingRight: "16px",
					}}>
					{sectors.map((sector, index) => (
						<div
							key={index}
							style={{
								backgroundColor: "#F1F1F1",
								padding: "8px 16px",
								borderRadius: "8px",
								cursor: "pointer",
								height: window.innerWidth < window.innerHeight ? "60px" : "30px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
							onClick={() => {
								mixpanel.identify(getPhoneNumber());
								mixpanel.track("onb. Avançar Setor - Web Business");
								navigate("/selecionar-categorias", {
									state: {
										phone: businessState.telephoneNumber,
										fullName: businessState.fullName,
										email: businessState.email,
										businessName: businessState.businessName,
										sectorId: sector.id,
									},
								});
							}}>
							<Text style={{ color: "#3E3E3E" }} align={"center"}>
								{sector.name}
							</Text>
						</div>
					))}
				</div>
			</Container>
		)
	);
};

export default SelectSector;
