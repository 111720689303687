import React from "react";
import { useNavigate } from "react-router";
import { ArrowBackIos } from "@material-ui/icons";
import { Typography } from "@mui/material";

import { ProgressBar } from "components";

const CoreAppBar = ({ progress, text, sendTo = null }) => {
	const navigate = useNavigate();
	return (
		<div
			style={{
				display: "flex",
				gap: "16px",
				alignItems: "center",
				justifyContent: "space-between",
				width: "100%",
			}}
			onClick={() => navigate(sendTo ? sendTo : -1)}>
			<ArrowBackIos style={{ width: "30px", color: " #616161", cursor: "pointer" }} />
			{progress && <ProgressBar progress={progress} />}
			{text && <Typography style={{ color: "#3E3E3E", fontSize: "22px", fontWeight: "bold" }}>{text}</Typography>}
			<div style={{ width: "30px" }}></div>
		</div>
	);
};

export default CoreAppBar;
