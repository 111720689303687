import React, { useEffect, useState } from "react";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { ExpandLess, ExpandMore, Menu } from "@material-ui/icons";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { useBusiness } from "context/business.context";

import { convertMonth } from "utils";
import { Text } from "components";

import "styles/calendar/index.scss";

const Calendar = (props) => {
	const backgroundColor = props?.backgroundColor;
	const selectedColor = props?.color || "#c2dbf8";
	const selectedDate = new Date(props?.date);
	const [actualDate, setActualDate] = useState(selectedDate);
	const [isExpanded, setIsExpanded] = useState(false);
	const { toggleMenu } = useBusiness();

	const { onChange, setDate, daysToNotify, daysBreakNotify } = props;
	const daysOfWeek = ["D", "S", "T", "Q", "Q", "S", "S"];

	const isSmallScreen = useMediaQuery("(max-width: 820px)");

	const getMobileWeek = () => {
		const currentDay = new Date();
		if (currentDay.getMonth() === actualDate.getMonth() && currentDay.getFullYear() === actualDate.getFullYear()) {
			const firstDayOfMonth = new Date(actualDate.getFullYear(), actualDate.getMonth(), 1);
			const currentWeek = Math.floor((currentDay.getDate() + firstDayOfMonth.getDay() - 1) / 7);
			return daysOfMonth[currentWeek] || [];
		}
		return daysOfMonth[0];
	};

	const getDaysOfMonth = () => {
		const days = [[], [], [], [], [], []];
		const firstDay = new Date(actualDate.getFullYear(), actualDate.getMonth(), 1);
		const lastDay = new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 0).getDate();
		const previousMonthLastDay = new Date(actualDate.getFullYear(), actualDate.getMonth(), 0).getDate();
		let j = 0;

		for (let i = firstDay.getDay() - 1; i >= 0; i--) {
			days[j].push({ day: previousMonthLastDay - i, isPreviousMonth: true });
		}

		for (let i = 1; i <= lastDay; i++) {
			days[j].push({ day: i, isPreviousMonth: false });
			if (days[j].length === 7 && j < 5) {
				j++;
			}
		}

		const remainingLength = 42 - lastDay - firstDay.getDay();

		for (let i = 0; i < remainingLength; i++) {
			days[j].push(null);
			if (days[j].length === 7 && j < 5) {
				j++;
			}
		}

		return days;
	};

	const [daysOfMonth, setDaysOfMonth] = useState([]);

	const nextMonth = () => {
		if (actualDate.getMonth() === 11) {
			setActualDate(new Date(actualDate.getFullYear() + 1, 0, 1));
		} else {
			setActualDate(new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 1));
		}
	};

	const prevMonth = () => {
		if (actualDate.getMonth() === 0) {
			setActualDate(new Date(actualDate.getFullYear() - 1, 11, 1));
		} else {
			setActualDate(new Date(actualDate.getFullYear(), actualDate.getMonth() - 1, 1));
		}
	};

	useEffect(() => {
		setDaysOfMonth(getDaysOfMonth());
	}, [actualDate]);

	const haveNotificationTo = (day) => {
		for (let i = 0; i < daysToNotify.length; i++) {
			const notifyDate = new Date(daysToNotify[i]);
			if (
				notifyDate.getDate() + 1 === day &&
				notifyDate.getMonth() === actualDate.getMonth() &&
				notifyDate.getFullYear() === actualDate.getFullYear()
			) {
				return true;
			}
		}
		return false;
	};

	const haveNotificationBrakes = (day) => {
		for (let i = 0; i < daysBreakNotify.length; i++) {
			const daysBreakToNotify = new Date(daysBreakNotify[i]);
			if (
				daysBreakToNotify.getDate() + 1 === day &&
				daysBreakToNotify.getMonth() === actualDate.getMonth() &&
				daysBreakToNotify.getFullYear() === actualDate.getFullYear()
			) {
				return true;
			}
		}
		return false;
	};

	const handleDateClick = (day, isPreviousMonth) => {
		if (day != null && !isPreviousMonth) {
			const newDate = new Date(actualDate.getFullYear(), actualDate.getMonth(), day);
			setDate(newDate);
			onChange(newDate); // Envie a data selecionada para o callback
		}
	};

	return (
		<div className="calendar-container">
			<div className="container2">
				<div
					style={{
						backgroundColor: backgroundColor,
						width: "100%",
						padding: "5px",
						boxSizing: "border-box",
					}}>
					<div className="calendarHeader">
						<button type="button" className="menu-mobile" onClick={toggleMenu}>
							<Menu />
						</button>
						<div className="calendar-date">
							<Text> {`${convertMonth(actualDate.getMonth())} ${actualDate.getFullYear()}`} </Text>
							<div className="containerMonthNav">
								<ArrowBackIosNewIcon
									onClick={prevMonth}
									style={{
										color: "#616161",
										fontSize: "18px",
										cursor: "pointer",
									}}
								/>
								<ArrowForwardIosIcon
									onClick={nextMonth}
									style={{
										color: "#616161",
										fontSize: "18px",
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="containerCalendarGrid">
					<div className="container-calendar-weekday">
						{daysOfWeek.map((day, index) => (
							<Text key={`${day}-${index}`} className="calendar-weekday">
								{day}
							</Text>
						))}
					</div>
					<div
						className={`calendar-grid ${isExpanded ? "expanded" : "collapsed"}`}
						style={{
							flexDirection: isSmallScreen ? (isExpanded ? "column" : "row") : "row",
							flexWrap: isSmallScreen ? (isExpanded ? "wrap" : "nowrap") : "wrap",
							overflowX: isSmallScreen && !isExpanded ? "auto" : "hidden",
							whiteSpace: isSmallScreen && !isExpanded ? "nowrap" : "normal",
						}}>
						{isSmallScreen && !isExpanded
							? getMobileWeek().map((dayObj, dayIndex) =>
									dayObj ? (
										<Text
											key={dayIndex}
											onClick={() => setIsExpanded(true)}
											className={`calendar-day ${
												selectedDate.getDate() === dayObj.day &&
												selectedDate.getMonth() === actualDate.getMonth() &&
												selectedDate.getFullYear() === actualDate.getFullYear()
													? "selected-day"
													: ""
											}`}
											style={{
												backgroundColor:
													selectedDate.getDate() === dayObj.day &&
													selectedDate.getMonth() === actualDate.getMonth() &&
													selectedDate.getFullYear() === actualDate.getFullYear()
														? selectedColor
														: "transparent",
											}}>
											{dayObj.day}
											{daysToNotify?.length > 0 && !dayObj.isPreviousMonth && haveNotificationTo(dayObj.day) && (
												<div className="notification-dot" />
											)}
											{daysBreakNotify?.length > 0 && !dayObj.isPreviousMonth && haveNotificationBrakes(dayObj.day) && (
												<div className="scheduleBrake-dot" />
											)}
										</Text>
									) : (
										<div key={dayIndex} className="calendar-day empty"></div>
									)
							)
							: daysOfMonth.map((week, weekIndex) => (
									<div key={weekIndex} className="calendar-week">
										{week.map((dayObj, dayIndex) =>
											dayObj ? (
												<Typography
													key={dayIndex}
													onClick={() => handleDateClick(dayObj.day, dayObj.isPreviousMonth)}
													className={`calendar-day ${
														selectedDate.getDate() === dayObj.day &&
														!dayObj.isPreviousMonth &&
														selectedDate.getMonth() === actualDate.getMonth() &&
														selectedDate.getFullYear() === actualDate.getFullYear()
															? "selected-day"
															: dayObj.isPreviousMonth
															? "previous-month"
															: ""
													}`}
													style={{
														backgroundColor:
															selectedDate.getDate() === dayObj.day &&
															!dayObj.isPreviousMonth &&
															selectedDate.getMonth() === actualDate.getMonth() &&
															selectedDate.getFullYear() === actualDate.getFullYear()
																? selectedColor
																: "transparent",
													}}>
													{dayObj.day}
													{daysToNotify?.length > 0 && !dayObj.isPreviousMonth && haveNotificationTo(dayObj.day) && (
														<div className="notification-dot" />
													)}
													{daysBreakNotify?.length > 0 &&
														!dayObj.isPreviousMonth &&
														haveNotificationBrakes(dayObj.day) && <div className="scheduleBrake-dot" />}
												</Typography>
											) : (
												<div key={dayIndex} className="calendar-day empty"></div>
											)
										)}
									</div>
							))}
					</div>

					{isSmallScreen && (
						<Button
							onClick={() => setIsExpanded(!isExpanded)}
							style={{
								display: "block",
								margin: "-5px auto",
								padding: "5px",
							}}>
							{isExpanded ? <ExpandLess /> : <ExpandMore />}
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default Calendar;
