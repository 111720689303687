import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useMixpanel } from "react-mixpanel-browser";
import { Popover, Typography } from "@mui/material";

import { Icons } from "components";

import useStyles from "../styles";

const LinkItem = ({ dynamicLink }) => {
	const classes = useStyles();
	const mixpanel = useMixpanel();

	const { me } = useSelector((state) => state.me);

	const [anchorEl, setAnchorEl] = useState(null);

	const handleCopy = (event, link) => {
		navigator.clipboard.writeText(link);
		setAnchorEl(event.currentTarget);
		mixpanel.identify(me.phone ?? me.phoneUnverified);
		mixpanel.track("Copiou link site - Web Business");
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleOpen = (link) => {
		window.open(link, "_blank");
		mixpanel.identify(me.phone ?? me.phoneUnverified);
		mixpanel.track("Compartilhou link site - Web Business");
	};

	const isOpenPopover = Boolean(anchorEl);

	return (
		<>
			<div className={classes.linkItem}>
				<p style={{ color: "#3688EB" }} className={classes.textLinkBusiness}>
					{dynamicLink}
				</p>
				<Icons.Copy
					onClick={(event) => handleCopy(event, dynamicLink)}
					style={{ marginRight: "10px", width: "16px", height: "16px", cursor: "pointer" }}
				/>
				<Icons.Open
					onClick={() => handleOpen(dynamicLink)}
					style={{ marginRight: "10px", width: "16px", height: "16px", cursor: "pointer" }}
				/>
				<Popover
					open={isOpenPopover}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}>
					<Typography sx={{ p: 2 }}>Link copiado!</Typography>
				</Popover>
			</div>
			<div style={{ marginLeft: "6%", marginTop: "-10px" }}>
				<p style={{ fontSize: "14px", color: "darkgray" }}>
					<span style={{ fontSize: "20px", color: "#3B972D", marginRight: "8px" }}>•</span> Seu site está online!
				</p>
			</div>
		</>
	);
};

export default LinkItem;
