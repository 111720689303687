import React, { useEffect, useState } from 'react';
import { ArrowBackIos } from '@material-ui/icons';
import Avatar from '@mui/material/Avatar';
import { Button as GlobalButton, GradientLoading } from "components";
import { Box } from "@material-ui/core";
import ProfessionalDetailsComponent from '../../components/professionalDetailsComponent';
import CoreContainer from '../../components/container';
import bookingTransactionService from '../../services/bookingTransactionService';
import { getAuth } from "firebase/auth";
import { Text } from 'components';
import './styles.scss';
import _ from 'lodash';
import { useConfig } from '../../context/config.context';
import { useSelector } from 'react-redux';

const NewReservationDialog = ({ reservationList, onClose }) => {

  const [reservations, setReservations] = useState(reservationList);

  const auth = getAuth();
  const { business } = useSelector((store) => store.business);
  const { handleFeedback, handleLoading } = useConfig();

  const handleDeleteService = (index) => {
    setReservations(prev => prev.filter((_, i) => i !== index));
  };

  const handleEditReservation = (index, updatedBooking) => {
    setReservations(prev =>
      prev.map((booking, i) => (i === index ? updatedBooking : booking))
    );
  };

  const postBooking = async () => {
    if (business && auth?.currentUser) {
      handleLoading(true);
      try {
        const bookings = _.map(reservations, (item) => ({
          ...item,
        }));

        const perChunk = 10;
        const chunkedBookings = bookings.reduce((resultArray, current, index) => {
          const chunkIndex = Math.floor(index / perChunk);
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [];
          }
          resultArray[chunkIndex].push(current);
          return resultArray;
        }, []);

        const errorList = [];

        for (let i = 0; i < chunkedBookings.length; i += 1) {
          const chunk = chunkedBookings[i];
          try {
            const dataToSend = {
              bookings: chunk,
              internalNote: "Observação interna",
              messageClient: "",
            };
            const response = await bookingTransactionService.postBookingTransaction(dataToSend);

            if (response.status === 200) {
              handleLoading(false);
              handleFeedback("Sucesso", "Reserva salva com sucesso.", "success");
              window.location.reload();
            }
          } catch (err) {
            errorList.push(`Erro no chunk ${i + 1} de ${chunkedBookings.length}`);
            handleFeedback(
              "Erro",
              `Falha ao salvar reservas no bloco ${i + 1}.`,
              "error"
            );
          }
        }
        if (errorList.length) {
          handleLoading(false);
          handleFeedback(
            "Atenção",
            `Algumas reservas falharam. (${errorList.join(", ")})`,
            "warning"
          );
        } else {
          handleLoading(false);
          handleFeedback("Sucesso", "Todas as reservas foram salvas!", "success");
          window.location.reload();
        }
      } catch (error) {
        handleLoading(false);
        handleFeedback("Erro", "Ocorreu um erro ao salvar as reservas.", "error");
      }
    }
  };

  return (
    <CoreContainer transparent={true} style={{ padding: "1rem", background: "none" }}>

      <header className="headerContainer">
        <ArrowBackIos
          style={{ width: "30px", color: "#616161", cursor: "pointer" }}
          onClick={onClose}
        />
        <Text>Nova Reserva</Text>
      </header>

      <section className='professionalContainer'>
        <div className='professionalContent'>
          <div>
            <Avatar src="/broken-image.jpg"/>
          </div>
          <div>
            <Text>
              {reservationList[0].professional.name}
            </Text>
            <Text variant={'body1'}>
              {reservationList[0].professional.phone}
            </Text>
          </div>
        </div>
      </section>

      <section className='listServices'>
        {reservations.map((reservation,index)=> {
          return (
            <ProfessionalDetailsComponent
            key={reservation.temp_id || index}
            booking={reservation}
            onDelete={()=> handleDeleteService(index)}
            onUpdate={(updatedBooking) => handleEditReservation(index, updatedBooking)}
          />
          );
        })}
      </section>

      <footer className='buttonContainer'>
        <GlobalButton
          type='button'
          title='Salvar'
          variant={'outlined'}
          className={'btn-color-primary'}
          handleClick={postBooking}
        />
      </footer>
    </CoreContainer>
  );
};

export default NewReservationDialog;
