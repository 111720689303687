import React from "react";
import { useSelector } from "react-redux";
import { Close } from "@material-ui/icons";
import { Divider } from "@mui/material";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { useBusiness } from "context/business.context";

import { LogoutDialog, PopOver, Text } from "components";
import MenuItem from "./components/MenuItem";
import LinkItem from "./components/LinkItem";
import Calendar from "../Calendar";
import ChangeBusiness from "../changeBusiness";

import { isMobile } from "utils/isMobile";

import clsx from "clsx";
import styled from "styled-components";
import useStyles from "./styles";
import "styles/schedule/index.scss";

export default function Sidebar() {
	const business = useSelector((store) => store.business.business);
	const {
		date,
		setShowCalendar,
		handleSetDate,
		setPageType,
		businessData,
		Menus,
		pageType,
		isOpenModalLogout,
		setIsOpenModalLogout,
		anchorEl,
		handleClosePopover,
		reservationDates,
		showMenu,
		toggleMenu,
		scheduleBreakDates,
		isSchedule,
		isOpenModalChangeBusiness,
		setOpenModalChangeBusiness,
	} = useBusiness();
	const classes = useStyles();

	const validateShowMenu = () => {
		if (isMobile()) {
			return showMenu;
		}
		return true;
	};

	return (
		<>
			<div className={clsx("calendar", showMenu && "sidebar")}>
				<div className="header-menu">
					<button
						className={clsx("date-header-name", showMenu && "show")}
						onClick={() => {
							if (isMobile()) toggleMenu();
							setPageType("root");
						}}>
						{business?.thumbnailUrl ? (
							<SyledLogo backgroundImage={business?.thumbnailUrl} />
						) : (
							<AccountCircleIcon
								style={{
									color: "darkgray",
									width: "40px",
									height: "40px",
									marginRight: "10px",
								}}
							/>
						)}
						<Text className="menu-business-name">{business?.name || "Empresa sem nome"}</Text>
					</button>

					<button
						className={clsx("close-menu", showMenu ? "show" : "hiden")}
						onClick={() => {
							toggleMenu();
						}}>
						<Close />
					</button>
				</div>
				<Divider />
				{(!isMobile() || isSchedule) && (
					<div className={clsx("calendar-header", showMenu && "hiden")}>
						<Calendar
							update
							date={date}
							onChange={() => setShowCalendar(false)}
							setDate={(e) => handleSetDate(e)}
							daysToNotify={reservationDates}
							daysBreakNotify={scheduleBreakDates}
						/>
					</div>
				)}
				<div
					className={clsx(`date-header ${classes.menu}`, validateShowMenu() ? "show" : "hiden")}
					style={{
						flexDirection: "column",
						alignItems: "flex-start",
						marginBottom: "10px",
					}}>
					{businessData && businessData.dynamicLink.length && <LinkItem dynamicLink={businessData.dynamicLink} />}
					{Menus.map((menu, index) => {
						const isActive = pageType === menu.page;
						return (
							<div
								style={{
									marginBottom: "0.5rem",
									cursor: "pointer",
									width: "99%",
								}}
								key={index}
								className={clsx({ [classes.active]: isActive })}
								onClick={(event) => {
									menu.action(event);
								}}>
								<MenuItem
									title={menu.title}
									icon={menu.icon}
									action={menu.action}
									page={menu.page}
									isActive={isActive}
								/>
							</div>
						);
					})}
				</div>
			</div>
			<PopOver anchorEl={anchorEl} setAnchorEl={handleClosePopover} />
			{isOpenModalLogout && (
				<LogoutDialog isOpen={isOpenModalLogout} handleClose={() => setIsOpenModalLogout(!isOpenModalLogout)} />
			)}

			{isOpenModalChangeBusiness && (
				<ChangeBusiness
					isOpen={isOpenModalChangeBusiness}
					handleClose={() => setOpenModalChangeBusiness(!isOpenModalChangeBusiness)}
				/>
			)}
		</>
	);
}

const SyledLogo = styled.div`
	background-color: #d9d9d9;
	background-image: url("${(props) => props.backgroundImage || "none"}");
	background-size: cover;
	background-position: center;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	margin-right: 10px;
`;
