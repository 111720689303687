import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import LoginPage from "../views/loginPage";
import CodePage from "../views/codePage";
import PersonalDataPage from "../views/personalDataPage";
import WelcomePage from "../views/welcomePage";
import SchedulePage from "../views/schedule";
import Payment from "../views/payment";
import PaymentMethods from "../views/payment/content/paymentMethods";
import PaymentPix from "../views/payment/content/paymentPix";
import PaymentCredit from "../views/payment/content/paymentCredit";
import CurrentPlan from "../views/payment/content/currentPlan";
import PaymentPixQrCode from "../views/payment/content/paymentPixQrCode";
import DetailsPayment from "../views/payment/content/detailsPayment";
import PaymentCancel from "../views/payment/content/paymentCancel";
import PaymentCancelFeedback from "../views/payment/content/paymentCancelFeedback.jsx";
import Address from "../views/address/content/address";
import SelectSector from "../views/onboarding/content/selectSector";
import SelectCategories from "../views/onboarding/content/selectCategories";

import { BusinessProvider } from "../context/business.context";
import { ConfigProvider } from "../context/config.context";
import { LoginProvider } from "../context/login.context";

import { Feedback, Loading } from "../components";

const Router = () => {
	return (
		<BusinessProvider>
			<ConfigProvider>
				<LoginProvider>
					<Feedback />
					<Loading />
					<BrowserRouter>
						<Routes>
							<Route path="/login" element={<LoginPage />} />
							<Route path="/welcome" element={<WelcomePage />} />
							<Route path="/subscription/:type" element={<Payment />} />
							<Route path="/login/code" element={<CodePage />} />
							<Route path="/login/data" element={<PersonalDataPage />} />
							<Route path="/menu/:id/:token" element={<Payment />} />
							<Route path="/menu" element={<Payment />} />
							<Route path="/subscription/:id/:token" element={<Payment />} />
							<Route path="/subscription/methods" element={<PaymentMethods />} />
							<Route path="/subscription/plan" element={<CurrentPlan />} />
							<Route path="/subscription/method/pix" element={<PaymentPix />} />
							<Route path="/subscription/qrcode/pix" element={<PaymentPixQrCode />} />
							<Route path="/subscription/method/credit" element={<PaymentCredit />} />
							<Route path="/subscription/details" element={<DetailsPayment />} />
							<Route path="/subscription/cancelar" element={<PaymentCancel />} />
							<Route path="/subscription/cancelar/feedback" element={<PaymentCancelFeedback />} />
							<Route path="/address" element={<Address />} />
							<Route path="/selecionar-setores" element={<SelectSector />} />
							<Route path="/selecionar-categorias" element={<SelectCategories />} />
							<Route path="/" element={<Payment />} />
							<Route path="/schedule" element={<SchedulePage />} />
							<Route path="/*" element={<WelcomePage />} />
						</Routes>
					</BrowserRouter>
				</LoginProvider>
			</ConfigProvider>
		</BusinessProvider>
	);
};

export default Router;
