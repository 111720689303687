import React, { useEffect, useState } from "react";
import { useMixpanel } from "react-mixpanel-browser";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, Divider } from "@mui/material";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@material-ui/icons";

import { useConfig } from "context/config.context";
import { useLogin } from "context/login.context";
import sectorService from "services/sectorService";
import businessService from "services/businessService";
import { checkNewsServices } from "services/ServicesService";
import businessAction from "store/actions/businessAction";

import { HoursSchema } from "helpers/schemas";
import {AppBar, Chip, Container, Button as GlobalButton, Text } from "components";
import { trimTelephoneNumber } from "utils";

import useStyles from "./styles";

const SelectCategories = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const mixpanel = useMixpanel();

	const { me } = useSelector((state) => state.me);
	const { setLoading } = useConfig();
	const { businessState, clearBusinessState } = useLogin();

	const [openModal, setOpenModal] = useState(false);
	const [modalMessage, setModalMessage] = useState("");
	const { sectorId } = location?.state || {};

	const [selectCategoryIds, setSelectedCategoryIds] = useState([]);
	const [categories, setCategories] = useState([]);

	const dispatch = useDispatch();

	const getPhoneNumber = () => {
		if (me) {
			return me.phone ?? me.phoneUnverified;
		} else {
			return "+" + businessState?.ddi + trimTelephoneNumber(businessState?.telephoneNumber);
		}
	};

	useEffect(() => {
		setLoading(true);
		if (sectorId === undefined) {
			navigate("/selecionar-setores");
		}
		const request = setTimeout(() => {
			sectorService.getSectorById(sectorId).then((res) => {
				setCategories(res.data.categories);
				setLoading(false);
			});
		}, 1000);

		return () => clearInterval(request);
	}, []);

	const handleGetFirstService = async () => {
		const listServices = await checkNewsServices(selectCategoryIds, null, {}, "portuguese", false);
		return orderServicesByName(listServices.data);
	};

	const orderServicesByName = (services) => {
		return services.sort((a, b) => a.name.localeCompare(b.name));
	};

	const handleCreateBusiness = async () => {
		try {
			setLoading(true);
			const services = await handleGetFirstService();
			const response = await businessService.postBusiness({
				name: businessState?.businessName,
				phone: "+" + businessState?.ddi + trimTelephoneNumber(businessState?.telephoneNumber),
				timeZone: "America/Sao_Paulo",
				currency: "BRL",
				sectorId: sectorId,
				categoryId: selectCategoryIds,
				schedulingType: "schedule",
				serviceType: "business",
				status: "inactive",
				language: "portuguese",
				bookingStartInterval: 30,
				business_services: [
					{
						serviceId: services[0].id,
						duration: services[0].duration,
						price: services[0].price,
						pricingTypeId: services[0].pricingTypeId,
					},
				],
				businessHours: HoursSchema.defaultInitialHours,
			});
			if (response.status >= 200 && response.status < 300) {
				setLoading(false);
				const businessData = response.data;

				dispatch(
					businessAction.setBusinessInfo({
						id: businessData.id,
						name: businessData.name,
						email: businessData.email || "",
						createdAt: businessData.createdAt,
					})
				);
				clearBusinessState();
				mixpanel.identify(getPhoneNumber());
				mixpanel.people.set({
					$name: businessState?.fullName || "Nome padrão",
					$email: businessState?.email,
				});
				mixpanel.track("Cadastro empresa finalizado - Web Business");
				navigate("/subscription/onboarding");
			}
		} catch (error) {
			setLoading(false);
			setModalMessage(error?.message);
			setOpenModal(true);
		}
	};

	return (
		<Container style={{ gap: "16px" }}>
			<div style={{ display: "flex", alignContent: "center", width: "100%", marginBottom: "1rem" }}>
				<AppBar progress={90} />
				<Chip help={true} />
			</div>

			<Text style={{ color: "#3E3E3E" }} variant={"mid"}>
				Com o que você trabalha?
			</Text>

			<div style={{ overflowY: "scroll", overflowX: "hidden", width: "100%", gap: "16px" }}>
				{categories
					.sort((a, b) => a.name.localeCompare(b.name))
					.map((category) => (
						<div
							key={category.id}
							style={{
								backgroundColor: " #F1F1F1",
								padding: "8px 16px",
								borderRadius: "8px",
								cursor: "pointer",
								alignItems: "center",
								display: "flex",
								justifyContent: "space-between",
								marginBottom: "8px",
								marginRight: "8px",
							}}
							onClick={() => {
								if (selectCategoryIds.includes(category.id)) {
									setSelectedCategoryIds(selectCategoryIds.filter((id) => id !== category.id));
								} else {
									setSelectedCategoryIds([...selectCategoryIds, category.id]);
								}
							}}>
							<Text style={{ color: "#3E3E3E" }}>{category.name}</Text>
							{selectCategoryIds.includes(category.id) ? (
								<CheckBoxOutlined htmlColor="#3E3E3E" />
							) : (
								<CheckBoxOutlineBlank htmlColor="#3E3E3E" />
							)}
						</div>
					))}
			</div>

			<Dialog
				open={openModal}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 400 },
				}}>
				<Text style={{ margin: "30px", textAlign: "center", fontSize: "calc(0.5vw + 10px)" }}>
					<strong className={classes.littlePurple}>{modalMessage}</strong>
				</Text>
				<Divider></Divider>
				<Button
					className={classes.littleBlue}
					style={{ padding: "15px", fontSize: "calc(0.5vw + 10px)" }}
					onClick={() => setOpenModal(false)}>
					OK
				</Button>
			</Dialog>

			<div style={{ flex: 1 }}></div>

			<GlobalButton
				title="Avançar"
				type="button"
				variant="contained"
				disabled={selectCategoryIds.length < 1}
				handleClick={() => {
					handleCreateBusiness();
					mixpanel.identify(getPhoneNumber());
					mixpanel.track("onb. Avançar Categoria - Web Business");
				}}
				className={`btn default ${selectCategoryIds.length < 1 ? "btn-color-secondary" : "btn-color-primary"}`}
				fullWidth={true}
			/>
		</Container>
	);
};

export default SelectCategories;
